import React, { useState } from "react";
import PropTypes from "prop-types";

import { Column, Row, Text } from "components";
import Timeline from "@mui/lab/Timeline";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import { useNavigate } from "react-router-dom";
import TimelineElement from "./TimelineElement";
import { useGetOrdersClientQuery } from "features/order/ordersApiSlice";
import moment from "moment";
import InstitutionLookUp from "components/LookUpElements/institution";
import SearchBar from "components/Search";

const Timelines = ({
  data = [{}],

  seeMore,
  ...restProps
}) => {

  const { data: orders, isLoading, isSuccess } = useGetOrdersClientQuery();
  let list = [];
  list = orders?.map((item, index) => {
    if (index < 4) {
      return (
        <TimelineElement
          date={moment(item?.created_at).format("YYYY-MM-DD")}
          time={moment(item?.created_at).format("h:mm a")}
          desc={
            <InstitutionLookUp id={item?.client_institution} timeline={true} />
          }
          title={"Order #" + item?.id + ". " + item?.status}
        />
      );
    }
  });

  const none = (
    <div className="h-[300px] pt-[100px]">
      <Text>No data to preview</Text>
    </div>
  );
  return (
    <>
      <div className="sm:w-[400px] md:w-[500px] lg:w-[500px]">
        <Column>
          <Row>
            <div className="w-[100%]">
              <Row className="items-start">
                <Column className="justify-start">
                  <Text
                    className="not-italic text-gray_900 w-[auto]"
                    variant="h4"
                  >
                    Timeline
                  </Text>
                  <Text
                    className="md:mt-[3px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[8px] 3xl:mt-[9px] not-italic text-bluegray_300 w-[auto]"
                    variant="body2"
                  >
                    Order History Updates
                  </Text>
                </Column>
              </Row>
              {list == undefined || list?.length == 0 ? (
                none
              ) : (
                <>
                  <Timeline
                    sx={{
                      [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.2,
                        marginLeft: -2,
                        paddingLeft: 0,
                      },
                    }}
                  >
                    {list}
                  </Timeline>
                  <div className="hover:bg-gray_100 w-[12%] rounded-lg">
                    <Text
                      className="common-button ml-[5%] not-italic text-blue_labex"
                      variant="body3"
                      onClick={seeMore}
                    >
                      See more
                    </Text>
                  </div>
                </>
              )}
            </div>
          </Row>
        </Column>
      </div>
    </>
  );
};
Timelines.propTypes = {
  data: PropTypes.array,
  seeMore: PropTypes.func,
};
Timelines.defaultProps = {
  data: [
    {
      date: "Feb 21, 2022",
      time: "10:00 AM ",
      title: "New request. #XF-2357",
      desc: "ICL - 5500 Masks",
    },
    {
      date: "Feb 21, 2022",
      time: "10:00 AM ",
      title: "New request. #XF-2357",
      desc: "ICL - 100 Masks",
    },
    {
      date: "Feb 21, 2022",
      time: "10:00 AM ",
      title: "New request. #XF-2357",
      desc: "ICL - 100 Masks",
    },
    // {date:"Feb 21, 2022", time:'10:00 AM ', title:'New request. #XF-2357', desc:'ICL - 100 Masks'},
    {
      date: "Feb 21, 2022",
      time: "10:00 AM ",
      title: "New request. #XF-2357",
      desc: "ICL - 100 Masks",
    },
  ],

  seeMore: () => {
    
  },
};
export default Timelines;
