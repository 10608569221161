import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Toast from "components/Toast";
import { Column, Row, Stack, Text, Img, Input, Button } from "components";
import { useNavigate } from "react-router-dom";
import { useForgotPasswordSendEmailMutation } from "features/auth/authApiSlice";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const [sendEmail, { isLoading }] = useForgotPasswordSendEmailMutation();

  const initialValues = {
    email: "",
  };
  function handleNavigate10() {
    navigate("/forgotpasswordcheckemail");
  }
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
  });

  return (
    <>
      <Column className="grid items-center grid-cols-1 md:grid-cols-2">
        <div className="bg-bluegray_200 h-[50vh] md:h-[100vh]  grid ">
        <Img
                      src="/images/labex-logo.svg"
            className="lg:h-[200px] xl:h-[250px] 2xl:h-[211px] 3xl:h-[253px] w-[100%] my-[auto] object-scale-down"
            alt="Logo"
          />
         
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            try {
              const pass = await sendEmail({
                email: values.email,
              }).unwrap();
              setErrMsg("");
              navigate("/forgotpasswordcheckemail");
              //   resetForm();
            } catch (err) {
              if (!err) {
                setErrMsg("No Server Response");
              } else if (err.data) {
                setErrMsg(err.data.error);
              } else if (err.status === 400) {
                setErrMsg("Invalid Credentials");
              } else if (err.status === 401) {
                setErrMsg("Unauthorized");
              } else {
                setErrMsg("Failed to set a password");
              }
            }
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              {errMsg && <Toast message={errMsg} />}

              <Column className="gap-2 md:gap-4 mt-[10px]">
                <div className="h-[50vh] md:h-[100vh] items-center w-[80%] sm:w-[70%] md:w-[60%] mx-[auto] py-[10%] sm:py-[5%] md:py-[50%] lg:pt-[40%] xl:pt-[30%] justify-center  justify-items-center">
                  <Text
                    className="not-italic text-center text-gray_900 w-[auto]"
                    as="h1"
                    variant="h1"
                  >
                    Forgot Password
                  </Text>
                  <Text
                    className="leading-[18px] text-center lg:leading-[18px] xl:leading-[23px] 2xl:leading-[26px] 3xl:leading-[31px] lg:mt-[10px] xl:mt-[13px] 2xl:mt-[15px] 3xl:mt-[18px] not-italic text-bluegray_300 w-[auto]"
                    as="h6"
                    variant="h6"
                  >
                    We will send you reset instructions to the email mentioned
                    below.
                  </Text>
                  <Text
                    className="lg:mt-[34px] xl:mt-[42px] 2xl:mt-[48px] 3xl:mt-[57px] not-italic text-gray_900 w-[auto]"
                    variant="body1"
                  >
                    Email Address
                  </Text>
                  <Input
                    className="w-[100%]"
                    wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px]  flex h-[30px] mt-[6px] sm:h-[36px] sm:mt-[7px] md:h-[40px] md:mt-[8px] lg:h-[40px] lg:mt-[9px] w-[100%] xl:h-[45px] xl:mt-[10px]"
                    name="email"
                    id="email"
                    {...formik.getFieldProps("email")}
                  ></Input>
                  {formik.touched?.email && formik.errors?.email ? (
                    <div>
                      <Text
                        className="not-italic text-red_700_cc "
                        variant="body2"
                      >
                        {formik.errors.email}
                      </Text>
                    </div>
                  ) : null}
                  <Button
                    type="submit"
                    className="w-[100px] mt-4 mx-[auto]"
                    children={<Text>Submit</Text>}
                  ></Button>
                </div>
              </Column>
            </form>
          )}
        </Formik>
      </Column>
    </>
  );
};

export default ForgotPasswordPage;
