import React from "react";

import { Column, Row, Stack, Text, Img } from "components";
import { useNavigate } from "react-router-dom";
import ThreeDRotation from '@mui/icons-material/ThreeDRotation';

const ForgotPasswordCheckEmailPage = () => {
  const navigate = useNavigate();

  function handleNavigate6() {
    navigate("/");
  }

  return (
    <>
     <Column className='grid items-center grid-cols-1 md:grid-cols-2'>
     <div className="bg-bluegray_200 h-[50vh] md:h-[100vh]  grid ">
        <Img
                      src="/images/labex-logo.svg"
            className="lg:h-[200px] xl:h-[250px] 2xl:h-[211px] 3xl:h-[253px] w-[100%] my-[auto] object-scale-down"
            alt="Logo"
          />
         
        
      </div>
      <div className="h-[50vh] md:h-[100vh] items-center w-[80%] sm:w-[70%] md:w-[60%] mx-[auto] py-[10%] sm:py-[5%] md:py-[60%] lg:pt-[44%] xl:pt-[44%] justify-center  justify-items-center">
            <Text
              className="not-italic text-center text-gray_900 w-[auto]"
              as="h1"
              variant="h1"
            >
              Check your email
            </Text>
            <Text
              className="leading-[18px] text-center lg:leading-[18px] xl:leading-[23px] 2xl:leading-[26px] 3xl:leading-[31px] lg:mt-[10px] xl:mt-[13px] 2xl:mt-[15px] 3xl:mt-[18px] not-italic text-gray_900_99 w-[100%]"
              as="h6"
              variant="h6"
            >
              <span className="text-gray_900_99 font-poppins font-normal lg:text-[12px] xl:text-[15px] 2xl:text-[17px] 3xl:text-[20px]">
                We have sent a password reset link 
              </span>
              {/* <span className="text-gray_900_99 font-poppins font-semibold lg:text-[12px] xl:text-[15px] 2xl:text-[17px] 3xl:text-[20px]">
                abc@gmail.com
              </span> */}
              <span className="text-gray_900_99 font-poppins font-normal lg:text-[12px] xl:text-[15px] 2xl:text-[17px] 3xl:text-[20px]">
                .{" "}
              </span>
            </Text>
            <Text
              className="not-italic font-semibold text-center text-green_400 w-[auto]"
              as="h6"
              variant="h6"
              onClick={()=>navigate('/')}
            >
              Back to Login
            </Text>
           
      </div>
      </Column>
      
      
    </>
  );
};

export default ForgotPasswordCheckEmailPage;
