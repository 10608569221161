import {
  Box,
  InputLabel,
  LinearProgress,
  // MenuItem,
  // Select,
} from "@mui/material";
// import { MenuItem, Select } from "@material-ui/core";
import { Input, Column, Text, Button } from "components/";
import { Row } from "components/Row";
import Toast from "components/Toast";
import { useAddToCartMutation } from "features/order/ordersApiSlice";
import { Formik, Form } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

function NewOrder(data) {
  const [addToCart, { isLoading }] = useAddToCartMutation();

  const [successMessage, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #ffffff",
    borderRadius: 5,
    boxShadow: 24,
    p: 8,
  };

  const validationSchema = Yup.object().shape({
    brand: Yup.string().required("Brand is required"),
    quantity: Yup.number()
      .required("Quantity  is required")
      .min(1, "Must be greater than 0"),
  });
  const initialValues = {
    brand: "",
    quantity: 0,
  };
  return (
    <Box className="w-[60%] md:w-[600px] h-[75vh]" sx={style}>
      <div style={{ overflow: "scroll", height: "65vh" }}>
        <Text className="not-italic text-gray_900 w-[auto]" variant="h2">
          {data?.data.name}
        </Text>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            try {
              const itemData = await addToCart({
                product: data.data.id,
                quantity: values.quantity,
              }).unwrap();
              setErrMsg("");
              setSuccessMsg("Successfully added to Cart");
            } catch (err) {
              if (!err) {
                setErrMsg("No Server Response");
              } else if (err.data.detail) {
                setErrMsg(err.data.detail);
              } else if (err.status === 400) {
                setErrMsg("Invalid Credentials");
              } else if (err.status === 401) {
                setErrMsg("Unauthorized");
              } else {
                setErrMsg("Failed to create an item");
              }
            }
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Column className="gap-2 md:gap-4 mt-[10px]">
                {isLoading && (
                  <Box
                    sx={{ position: "relative", width: "100%", height: "50%" }}
                  >
                    <LinearProgress className="bg-blue_labex" />
                  </Box>
                )}
                {errMsg && <Toast message={errMsg} />}
                {successMessage && (
                  <Toast type="success" message={successMessage} />
                )}

                <Row className="items-center grid grid-cols-1 lg:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px]"
                    variant="h6"
                  >
                    Brand
                  </Text>

                  <select
                    // color="secondary"
                    className="px-3 bg-gray_50 2xl:mt-[9px]  3xl:mt-[10px] flex 3xl:h-[56px] 2xl:h-[47px] xl:h-[41px] lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[160px]  sm:w-[300px]  xl:mt-[8px] border border-gray_50 border-solid rounded-radius845"
                    id="brand"
                    label="Brand"
                    name="brand"
                    // value={data?.data.brand}
                    {...formik.getFieldProps("brand")}
                  >
                    {data?.data.brand != null && <option></option>}
                    <option value={data?.data.brand}>
                      {" "}
                      {data?.data.brand}{" "}
                    </option>
                    <option value="none">No brand</option>
                  </select>
                </Row>
                {formik.touched?.brand && formik.errors?.brand ? (
                  <div>
                    <Text
                      className="not-italic text-red_700_cc w-[auto]"
                      variant="body2"
                    >
                      {formik.errors.brand}
                    </Text>
                  </div>
                ) : null}

                <Row className="items-center grid grid-cols-1 lg:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px] mb-2"
                    variant="h6"
                  >
                    Quantity
                  </Text>
                  <Input
                    className="diasable w-[100%]"
                    wrapClassName="2xl:mt-[9px] 3xl:mt-[10px] flex md:h-[30px] lg:mt-[6px] w-[160px]  sm:w-[300px] xl:mt-[8px] 3xl:h-[56px] 2xl:h-[47px] xl:h-[41px] lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px]"
                    name="qty"
                    {...formik.getFieldProps("quantity")}
                  ></Input>
                </Row>
                {formik.touched?.quantity && formik.errors?.quantity ? (
                  <div>
                    <Text
                      className="not-italic text-red_700_cc w-[auto]"
                      variant="body2"
                    >
                      {formik.errors.quantity}
                    </Text>
                  </div>
                ) : null}
                <Button
                  type="submit"
                  className="w-[200px] mt-4"
                  children={<Text>Add to cart</Text>}
                ></Button>
              </Column>
            </form>
          )}
        </Formik>
      </div>
    </Box>
  );
}

export default NewOrder;
