import React, { forwardRef, useState } from "react";
import { Box, LinearProgress, Modal } from "@mui/material";
import { Column, Row, Text } from "components";
import { useNavigate } from "react-router-dom";
import Card from "../../../components/Card/index";
import Header from "components/Header/index.jsx";
import BasicPagination from "../../../components/Pagination/index";
import EditAccounts from "components/Modals/EditAccount/index.js";
import { faBoxesStacked, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddGlobalItem from "components/Modals/CreateGlobalItem";
import { useDispatch } from "react-redux";
import { logOut } from "features/auth/authSlice";
import {
  useGetGlobalItemsQuery,
  useGetReferenceItemsQuery,
} from "features/item/referenceItemApiSlice";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import {
  useDeleteUserMutation,
  useUpdateUserMutation,
} from "features/users/usersApiSlice";
import MaterialTable from "material-table";
import {
  useGetGlobalItemsClientQuery,
  useGetGlobalItemsOfficerQuery,
} from "features/item/clientItemsSlice";
import KitItems from "components/KitItems";
import { useGetReferenceKitQuery } from "features/item/referenceKitApiSlice";
import { BounceLoader } from "react-spinners";
import Footer from "components/Footer";
import { InfoOutlined } from "@material-ui/icons";
import KitInfo from "components/Modals/KitInfo";
import InstitutionLookUp from "components/LookUpElements/institution";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const GlobalInventoryPage = () => {
  const navigate = useNavigate();
  const [x, setX] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [kitId, setkitId] = React.useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const { data, isLoading, isSuccess, isError, refetch, isFetching, error } =
    useGetGlobalItemsOfficerQuery();
  const records = data?.filter((record) => {
    return record.soyo_price > 0;
  });
  const { data: result } = useGetReferenceItemsQuery();
  const {
    data: kitData,
    refetch: refetchKit,
    isLoadingKitData,
  } = useGetReferenceKitQuery(kitId);

  const [deleteUser, { isLoadingDelete }] = useDeleteUserMutation();
  const [updateUser, { isLoadingUpdate }] = useUpdateUserMutation();
  const editable = structuredClone(records);
  const selectedRow = React.useRef([]);
  const [successMessage, setSuccessMsg] = useState("");
  const handleClick = (rows) => {
    selectedRow.current = rows;
  };

  const columns = [
    {
      title: "ID",
      field: "id",
      align: "left",
      type: "numeric",
      editable: false,
      filterPlaceholder: "Filter",
    },
    {
      title: "Item Name",
      field: "name",
      align: "left",

      filterPlaceholder: "Filter",
    },
    {
      title: "Item Type",
      field: "product.type",
      align: "left",
      lookup: { item: "Item", kit: "Kit" },

      filterPlaceholder: "Filter",
    },
    {
      title: "Supplier",
      field: "owner_institution",
      align: "left",
      render: (rowData) => <InstitutionLookUp id={rowData.owner_institution} />,

      filterPlaceholder: "Filter",
    },
    {
      title: "Supplier Price",
      field: "supplier_price",
      align: "left",
      type: "currency",

      validate: (rowData) => {
        if (rowData.supplier_price <= 0)
          return "Price should be greater than 0";
        else if (
          rowData.supplier_price === undefined ||
          rowData.supplier_price == ""
        ) {
          return "Required";
        } else return true;
      },
      currencySetting: { currencyCode: "ETB", minimumFractionDigits: 0 },
      searchable: false,
      filterPlaceholder: "Filter",
    },
    {
      title: "Labex Price",

      field: "soyo_price",
      align: "left",
      type: "currency",
      validate: (rowData) => {
        if (rowData.soyo_price <= 0) return "Price should be greater than 0";
        else if (rowData.soyo_price === undefined || rowData.soyo_price == "") {
          return "Required";
        } else return true;
      },
      currencySetting: { currencyCode: "ETB", minimumFractionDigits: 0 },
      searchable: false,
      filterPlaceholder: "Filter",
    },
    {
      title: "Quantity",
      field: "quantity",
      align: "left",
      type: "numeric",

      validate: (rowData) => {
        if (rowData.quantity <= 0) return "Quantity should be greater than 0";
        else if (rowData.quantity === undefined || rowData.quantity == "")
          return "Required";
        else return true;
      },
      filterPlaceholder: "Filter",
    },
    {
      title: "Catalog Number",
      field: "catalog_number",
      align: "left",
      // type: "numeric",

      validate: (rowData) => {
        if (
          rowData.catalog_number === undefined ||
          rowData.catalog_number == ""
        )
          return "Required";
        else return true;
      },
      filterPlaceholder: "Filter",
    },
    {
      title: "Brand",
      field: "brand",
      align: "left",
      emptyValue: () => <em>Not Applicable</em>,
      // type: "strin",

      validate: (rowData) => {
        if (rowData.brand === undefined || rowData.brand == "")
          return "Required";
        else return true;
      },
      filterPlaceholder: "Filter",
    },
    {
      title: "Description",
      field: "description",
      align: "left",
      // type: "numeric",

      validate: (rowData) => {
        if (rowData.description === undefined || rowData.description == "")
          return "Required";
        else return true;
      },
      filterPlaceholder: "Filter",
    },
  ];
  var menu = [
    {
      title: "Orders",
      dropdown: true,
      entries: [
        { title: "View Orders", route_path: () => navigate("/officer/orders") },
        {
          title: "Order History",
          route_path: () => navigate("/officer/orderhistory"),
        },
      ],
      icon: "",
    },
    {
      title: "Items",
      dropdown: true,
      entries: [
        {
          title: "Add Global Item",
          route_path: () => {
            chooseModal(2);
            handleOpen();
          },
        },
        {
          title: " Global Inventory",
          route_path: () => navigate("/officer/globalinventory"),
        },
        {
          title: " Reference List",
          route_path: () => navigate("/officer/referenceList"),
        },

        {
          title: "Review Items",
          route_path: () => navigate("/officer/reviewitems"),
        },
      ],
      icon: "",
    },
    {
      dropdown: true,
      title: "",
      entries: [
        {
          title: "Account Setting",
          route_path: () => {
            navigate("/officer/account");
          },
        },
        {
          title: "Log Out",
          route_path: () => {
            dispatch(logOut());
            navigate("/");
          },
        },
      ],
      icon: faUser,
    },
  ];
  function chooseModal(idx, id) {
    switch (idx) {
      case 1:
        setX(<KitInfo id={id} />);
        break;
      case 2:
        setX(<AddGlobalItem />);
        break;
    }
  }
  return (
    <>
      <Modal
        open={open}
        sx={{ overflow: "fixed" }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {x ?? <AddGlobalItem />}
      </Modal>
      <Header homePath="/officer" menus={menu} />
      {isLoading ? (
        <Box
          sx={{ position: "absolute", top: "50%", left: "50%", height: "50px" }}
        >
          <BounceLoader
            color="#283660"
            // className="bg-green_400"
          />
        </Box>
      ) : (
        <>
          <Column className="mx-[auto] w-[90%] sm:w-[90%]  pb-[5rem] md:w-[80%] lg:w-[80%] xl:w-[80%]">
            <Row className="mb-[3%] content-end grid grid-cols-2">
              <Text variant="h1" className=" not-italic text-gray_900">
                Global Inventory
              </Text>
            </Row>
            <Column className="grid mb-2">
              <MaterialTable
                options={{
                  showTitle: false,
                  sorting: true,
                  emptyRowsWhenPaging: false,
                  search: true,
                  filtering: true,
                  pageSizeOptions: [5, 10, 15],
                  exportButton: true,
                  columnResizable: true,
                  exportAllData: true,
                  // selection: true,
                  showSelectAllCheckbox: true,
                  showTextRowsSelected: true,

                  selectionProps: (rowData) => ({ color: "primary" }),
                  headerSelectionProps: (header) => ({ color: "primary" }),
                  searchFieldAlignment: "left",
                  grouping: true,
                  columnsButton: true,
                  rowStyle: {
                    background: "rgba(40, 54, 96, 0.2)",
                    color: "#181c32",
                  },

                  headerStyle: {
                    background: "rgba(40, 54, 96, 0.2)",
                    color: "#181c32",
                    fontWeight: "bolder",
                  },
                  filterRowStyle: { background: "rgba(40, 54, 96, 0.2)" },
                  detailPanelColumnStyle: {
                    background: "rgba(40, 54, 96, 0.2)",
                  },
                  actionsColumnIndex: 10,
                }}
                onSelectionChange={(e) => {
                  handleClick(e);
                }}
                actions={[
                  (rowData) => {
                    return {
                      icon: InfoOutlined,
                      tooltip: "Get more information",
                      disabled: rowData.product.type == "item",
                      onClick: (event, rowData) => {
                        chooseModal(1, rowData.product.id);
                        handleOpen();
                      },
                    };
                  },
                ]}
                columns={columns}
                data={editable}
                icons={tableIcons}
              />
            </Column>
          </Column>
          <Footer />
        </>
      )}
    </>
  );
};

export default GlobalInventoryPage;
