import {
  Box,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Input, Column, Text, Button, Radio, RadioGroup } from "components/";
import { Row } from "components/Row";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import ReviewItemsPage from "pages/Officer/ReviewItems";
import React, { useState } from "react";
import {
  useCreateItemMutation,
  useCreateKitMutation,
} from "features/item/itemApiSlice";
import Toast from "components/Toast";
import { BounceLoader } from "react-spinners";

function AddItemsModal({ dataa, brand, name, description, isKit }) {
  const [addItem, { isLoading }] = useCreateItemMutation();
  const [addKit, { isLoadingKit }] = useCreateKitMutation();

  const [successMessage, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errors, setErrors] = useState([]);
  // const { data, isLoadingg, isSuccess, isError, refetch, isFetching, error } =
  //   useGetReferenceItemsQuery();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #ffffff",
    borderRadius: 5,
    boxShadow: 24,
    p: 8,
  };
  const validationSchema = Yup.object().shape({
    // item: Yup.object().required("Item Name is required"),
    // brand: Yup.string().required("Brand Name is required"),
    price: Yup.number().required("Price is required"),
    quantity: Yup.number().required("Quantity  is required"),
    catalogNumber: Yup.number().required("Catalog Number is required"),
  });
  const initialValues = {
    // item: { id: "", name: "", description: "" },
    price: "",
    quantity: "",
    catalogNumber: "",
    // brand: "",
  };

  return (
    <Box className="w-[80%] md:w-[600px] h-[75vh]" sx={style}>
      <div style={{ overflow: "scroll", height: "60vh" }}>
        <Text className="not-italic text-gray_900 w-[auto]" variant="h2">
          {brand ? "Add Item" : "Add Kit"}
        </Text>

        <Formik
          initialValues={initialValues}
          //  validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            try {
              if (isKit) {
                const itemData = await addKit({
                  refkit: dataa,
                  catalog_number: values.catalogNumber,
                  quantity: values.quantity,
                  supplier_price: values.price,
                }).unwrap();
                setErrMsg("");
                setErrors([]);
                setSuccessMsg("Successfully added kit");
                resetForm();
              } else {
                const itemData = await addItem({
                  refitem: dataa,
                  catalog_number: values.catalogNumber,
                  quantity: values.quantity,
                  supplier_price: values.price,
                }).unwrap();
                setErrors([]);
                setErrMsg("");
                setSuccessMsg("Successfully added an item");
              }
            } catch (err) {
              setErrors(err.data);
              if (!err) {
                setErrMsg("No Server Response");
              }
              // else if (err.data?.quantity)
              //   setErrMsg("For Quantity, " + err.data?.quantity[0]);
              // else if (err.data?.catalog_number)
              //   setErrMsg("For Catalog Number, " + err.data?.catalog_number[0]);
              // else if (err.data?.supplier_price)
              //   setErrMsg("For Supplier Price, " + err.data?.supplier_price[0]);
              // else if (err.status === 400) {
              //   setErrMsg("Invalid Credentials");
              // }
              else if (err.status === 401) {
                setErrMsg("Unauthorized");
              } else {
                setErrMsg("Failed to add an item");
              }
            }
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Column className="gap-2 md:gap-4 mt-[10px]">
                {isLoading ||
                  (isLoadingKit && (
                    <Box
                      sx={{
                        position: "relative",
                        top: 0,
                        width: "100%",
                        height: "50px",
                      }}
                    >
                      <LinearProgress className="bg-blue_labex" />
                    </Box>
                  ))}
                {errMsg && <Toast message={errMsg} />}
                {successMessage && (
                  <Toast type="success" message={successMessage} />
                )}
                {/* <Row className='items-center grid grid-cols-1 md:grid-cols-3'>
                <Text className="not-italic text-gray_900 w-[150px] mb-2" variant="h6">
                    Item Category
                </Text>
               <Input 
              className="diasable w-[100%]"
              wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px]  sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
              name="itemcategory"
              
            ></Input>
            </Row>   */}
                <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px]"
                    variant="h6"
                  >
                    {brand ? "Item Name" : "Kit Name"}
                  </Text>
                  <Input
                    className="w-[100%]"
                    wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px]  sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                    name="name"
                    disabled={true}
                    value={name}
                  ></Input>
                </Row>

                {brand ? (
                  <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                    <Text
                      className="not-italic text-gray_900 w-[150px] mb-2"
                      variant="h6"
                    >
                      Brand
                    </Text>
                    <Input
                      className="w-[100%]"
                      wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px]  sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                      name="brand"
                      disabled={true}
                      value={brand}
                    ></Input>
                  </Row>
                ) : (
                  <></>
                )}
                <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px] mb-2"
                    variant="h6"
                  >
                    Price
                  </Text>
                  <Input
                    className="w-[100%]"
                    wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px]  sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                    name="price"
                    {...formik.getFieldProps("price")}
                  ></Input>
                </Row>
                {errors?.price ? (
                  <div>
                    <Text
                      className="not-italic text-red_700_cc w-[auto]"
                      variant="body2"
                    >
                      {errors?.price[0]}
                    </Text>
                  </div>
                ) : null}
                <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px] mb-2"
                    variant="h6"
                  >
                    Quantity
                  </Text>
                  <Input
                    className="w-[100%]"
                    wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                    name="quantity"
                    {...formik.getFieldProps("quantity")}
                  ></Input>
                </Row>
                {errors?.quantity ? (
                  <div>
                    <Text
                      className="not-italic text-red_700_cc w-[auto]"
                      variant="body2"
                    >
                      {errors?.quantity[0]}
                    </Text>
                  </div>
                ) : null}
                <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px] mb-2"
                    variant="h6"
                  >
                    Catalog Number
                  </Text>
                  <Input
                    className="w-[100%]"
                    wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                    name="catalogNumber"
                    {...formik.getFieldProps("catalogNumber")}
                  ></Input>
                </Row>
                {errors?.catalog_number ? (
                  <div>
                    <Text
                      className="not-italic text-red_700_cc w-[auto]"
                      variant="body2"
                    >
                      {errors?.catalog_number[0]}
                    </Text>
                  </div>
                ) : null}
                <Button type="submit" className="w-[200px] mt-4">
                  Add Item{" "}
                </Button>
              </Column>
            </form>
          )}
        </Formik>
      </div>
    </Box>
  );
}

export default AddItemsModal;
