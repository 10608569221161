import React from "react";
import PropTypes from "prop-types";
import {
  faUser,
  faDownload,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

import { Column, Row, Img, Text, Button } from "components";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Card = ({
  icon,
  row11,
  row12,
  row21,
  row22,
  row31,
  row32,
  row41,
  row42,
  button1name,
  button1color,
  button2name,
  button2color,
  button1action,
  button2action,
  button3action,
  button2Icon,
  buttonStructure,
  user,
  modal,
  buttonWidth,
  ...restProps
}) => {
  const navigate = useNavigate();

  function handleNavigate() {
    navigate("/globalinv");
  }
  function handleNavigate4() {
    navigate("/editaccount");
  }
  function handleNavigate5() {
    navigate("/newuser");
  }

  return (
    <>
      {modal ? (
        // For Modals
        <div
          style={{
            borderRadius: 10,
            backgroundColor: "rgba(40, 54, 96, 0.2)",
          }}
          className="w-[90%] inline-block h-[60px] sm:h-[60px] md:h-[60px] lg:h-[70px] xl:h-[80px]"
        >
          <Row className="mx-3">
            <div className="w-[15%] sm:w-[12%]  mr-1 mt-[15px]">{icon}</div>
            <Column className="grid grid-rows-2 mt-[10px] xl:mt-[15px] w-[35%] sm:w-[38%]">
              <Text
                className="not-italic text-[20px] text-gray_900"
                variant="h6"
              >
                {row11}
              </Text>
              <Text className="text-bluegray_300" variant="h6">
                {row12}
              </Text>
            </Column>

            {row21 == "" ? (
              <></>
            ) : (
              <Column className="justify-self-end w-[15%] mt-[15px] ">
                <Text variant="h4">{row21}</Text>
                <Text className="text-bluegray_300" variant="h6">
                  {row22}
                </Text>
              </Column>
            )}

            {row31 == "" ? (
              <div className="w-[15%]"></div>
            ) : (
              <Column className="justify-self-end mt-[15px] lg:w-[180px] xl:w-[88px] 2xl:w-[215px] 3xl:w-[230px]">
                <Text variant="h4">{row31}</Text>
                <Text className="text-bluegray_300" variant="h6">
                  {row32}
                </Text>
              </Column>
            )}

            {button1name == "" ? (
              <Column className="mt-[15px] w-[15%]">
                <Text variant="h4">{row41}</Text>
                <Text className="text-bluegray_300" variant="h6">
                  {row42}
                </Text>
              </Column>
            ) : (
              <div className="w-[15%]">
                <Button
                  variant={button1color ? button1color : "FillIndigo900cc"}
                  className="ml-[auto] h-[40px] w-[90%] mt-[8px] md:mt-[10px] mt-[15px] md:mt-[10px] lg:mt-[15px] lg:ml-[18px] xl:ml-[23px] 2xl:ml-[26px] 3xl:ml-[31px]"
                  onClick={button1action}
                  children={
                    <FontAwesomeIcon
                      type="outline"
                      className="px-[auto]"
                      color="#ffffff"
                      fontSize={30}
                      width={20}
                      icon={faDownload}
                    />
                    // <Text>{button1name}</Text>
                  }
                ></Button>
              </div>
            )}

            {button2name == "" ? (
              <div className="w-[15%]">
                {button2Icon == null ? (
                  <Button
                    variant={"None"}
                    className="ml-[auto] h-[40px] w-[90%] mt-[8px] md:mt-[10px] mt-[15px] md:mt-[10px] lg:mt-[15px] lg:ml-[18px] xl:ml-[23px] 2xl:ml-[26px] 3xl:ml-[31px]"
                    onClick={button1action}
                    children={
                      <FontAwesomeIcon
                        type="outline"
                        className="px-[auto]"
                        color={button2color ? button2color : "#ffffff"}
                        fontSize={30}
                        width={20}
                        icon={button2Icon}
                      />
                      // <Text>{button1name}</Text>
                    }
                  ></Button>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div className="w-[25%]">
                <Button
                  variant={button2color ? button2color : "FillIndigo900cc"}
                  className="ml-[auto] h-[40px] w-[80%] mt-[8px] md:mt-[10px] mt-[15px] md:mt-[10px] lg:mt-[15px] lg:ml-[18px] xl:ml-[23px] 2xl:ml-[26px] 3xl:ml-[31px]"
                  onClick={button2action}
                  children={<Text>{button2name}</Text>}
                ></Button>
              </div>
            )}
          </Row>
        </div>
      ) : // For User Pages
      user ? (
        <div
          style={{
            borderRadius: 10,
            backgroundColor: "rgba(40, 54, 96, 0.2)",
          }}
          className="inline-block mb-[8px] sm:mb-[10px] md:mb-[15px] lg:mb-[20px] xl:mb-[12px] h-[60px] sm:h-[60px] md:h-[60px] lg:h-[70px] xl:h-[80px]"
        >
          <Row className="mx-3 ">
            <div class="mt-[3.5%] mr-[2%] sm:mt-[2%] w-[10%] sm:w-[8%] lg:w-[6%] xl:w-[4%]">
              {icon}
            </div>
            <Column className="md:mt-[15px] mt-[2%] w-[20%]">
              <Text className="not-italic text-gray_900" variant="h4">
                {row11}
              </Text>
              <Text className="text-bluegray_300" variant="body1">
                {row12}
              </Text>
            </Column>

            {/* <Column className="md:mt-[10px] mt-[2%] w-[15%]">

<Text variant='h4'>{row31}</Text>
<Text  className='text-bluegray_300' variant='body1'>{row32}</Text>

</Column> */}
            <Column className="md:mt-[15px] mt-[2%] w-[20%] ">
              <Text variant="h4">{row41}</Text>
              <Text className="text-bluegray_300" variant="body1">
                {row42}
              </Text>
            </Column>
            <div className="w-[20%]">
              <Button
                variant="FillIndigo900cc"
                onClick={button1action}
                className="ml-[auto] w-[90%] md:px-4  ml-[5px] mt-[15px] md:mt-[10px] lg:mt-[15px]  h-[30px] sm:h-[30px] md:h-[38px] lg:h-[35px] xl:h-[40px]"
                children={
                  <Text className="text-[12px] sm:text-[15px] lg:text-[16px]">
                    {button1name}
                  </Text>
                }
              ></Button>
            </div>

            <div className="w-[20%]">
              {" "}
              <Button
                variant={button2color}
                onClick={button2action}
                className="ml-[auto] w-[90%] md:px-4 ml-[5px] mt-[15px] md:mt-[10px] lg:mt-[15px] h-[30px] sm:h-[30px] md:h-[38px] lg:h-[35px] xl:h-[40px]"
                children={
                  <Text className="text-[12px] sm:text-[15px] lg:text-[16px]">
                    {button2name}
                  </Text>
                }
              ></Button>
            </div>
            <div className="w-[20%]">
              {" "}
              <Button
                variant="FillRed700cc"
                onClick={button3action}
                className="ml-[auto] w-[90%] md:px-4 ml-[5px] mt-[15px] md:mt-[10px] lg:mt-[15px] h-[30px] sm:h-[30px] md:h-[38px] lg:h-[35px] xl:h-[40px]"
                children={
                  <Text className="text-[12px] sm:text-[15px] lg:text-[16px]">
                    Delete
                  </Text>
                }
              ></Button>
            </div>
          </Row>
        </div>
      ) : (
        // For non user pages
        <div
          style={{
            borderRadius: 10,
            backgroundColor: "rgba(40, 54, 96, 0.2)",
          }}
          className="inline-block mb-[8px] sm:mb-[10px] md:mb-[15px] lg:mb-[20px] xl:mb-[12px] h-[60px] sm:h-[60px] md:h-[60px] lg:h-[70px] xl:h-[80px]"
        >
          <Row className="mx-3 ">
            <div class="mt-[3.5%] mr-[2%] sm:mt-[2%] w-[10%] sm:w-[8%] lg:w-[6%] xl:w-[4%]">
              {icon}
            </div>
            <Column className="md:mt-[15px] mt-[2%] w-[30%] sm:w-[32%] lg:w-[34%] xl:w-[36%]">
              <Text className="not-italic text-gray_900" variant="h4">
                {row11}
              </Text>
              <Text className="text-bluegray_300" variant="body1">
                {row12}
              </Text>
            </Column>

            <Column className="invisible md:visible md:mt-[10px] mt-[2%] w-[0%] ">
              <Text variant="h4">{row21}</Text>
              <Text className="text-bluegray_300" variant="body1">
                {row22}
              </Text>
            </Column>
            {/* <Column className="md:mt-[10px] mt-[2%] w-[15%]">

<Text variant='h4'>{row31}</Text>
<Text  className='text-bluegray_300' variant='body1'>{row32}</Text>

</Column> */}
            <Column className="md:mt-[15px] mt-[2%] w-[30%] ">
              <Text variant="h4">{row41}</Text>
              <Text className="text-bluegray_300" variant="body1">
                {row42}
              </Text>
            </Column>
            {button1name == "" ? (
              <div className={!buttonWidth ? "w-[15%]" : "w-[25%]"} />
            ) : (
              <div className="w-[15%]">
                <Button
                  variant="FillIndigo900cc"
                  onClick={button1action}
                  className="ml-[auto] w-[90%] md:px-4  ml-[5px] mt-[15px] md:mt-[10px] lg:mt-[15px]  h-[30px] sm:h-[30px] md:h-[38px] lg:h-[35px] xl:h-[40px]"
                  children={
                    <Text className="text-[12px] sm:text-[15px] lg:text-[16px]">
                      {button1name}
                    </Text>
                  }
                ></Button>
              </div>
            )}

            {button2name == "" ? (
              <div />
            ) : (
              <div className={!buttonWidth ? "w-[15%]" : "w-[25%]"}>
                {" "}
                <Button
                  variant={button2color}
                  onClick={button2action}
                  className="ml-[auto] w-[90%] md:px-4 ml-[5px] mt-[15px] md:mt-[10px] lg:mt-[15px] h-[30px] sm:h-[30px] md:h-[38px] lg:h-[35px] xl:h-[40px]"
                  children={
                    <Text className="text-[12px] sm:text-[15px] lg:text-[16px]">
                      {button2name}
                    </Text>
                  }
                ></Button>
              </div>
            )}
          </Row>
        </div>
      )}
    </>
  );
};
Card.propTypes = {
  icon: PropTypes.object,
  row11: PropTypes.string,
  row12: PropTypes.string,
  row21: PropTypes.string,
  row22: PropTypes.string,
  row31: PropTypes.string,
  row32: PropTypes.string,
  row41: PropTypes.string,
  row42: PropTypes.string,
  button1name: PropTypes.string,
  button1color: PropTypes.string,
  button2name: PropTypes.string,
  button2color: PropTypes.string,
  button1action: PropTypes.func,
  button2action: PropTypes.func,
  modal: PropTypes.bool,
  buttonWidth: PropTypes.bool,
};
Card.defaultProps = {
  icon: (
    <FontAwesomeIcon
      type="outline"
      className="px-[auto]"
      color="#ffffff"
      fontSize={30}
      width={40}
      icon={faUser}
    />
  ),
  row11: "",
  row12: "",
  row21: "",
  row22: "",
  row31: "",
  row32: "",
  row41: "",
  row42: "",
  modal: false,
  button1name: "",
  button1color: "FillIndigo900cc",
  button2name: "",
  button2color: "FillIndigo900cc",
  buttonWidth: false,
  button1action: () => {
  },
  button2action: () => {
  },
};
export default Card;
