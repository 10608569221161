import React from "react";
import PropTypes from "prop-types";
import { faTag, faBars, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { faProductHunt } from "@fortawesome/fontawesome-free-brands";
import {
  Column,
  Row,
  Img,
  Text,
  Button,
 
} from "components";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListItem from "../ListItem";
import { useGetDashboardQuery } from "features/dashboard/dashboardApiSlice";
// Done for all breakpoints
const RevenueList = (
  {
    title ,
    seeMore,
   
    ...restProps
  }
) => {
 function chooseIcon(icon) {
    switch (icon) {
        case 'tag': return faTag;   break;
        case 'producthunt': return faProductHunt;    break;
        case 'category': return faBars;     break;
        case 'moneybill': return faMoneyBill;
    }
 }

  const { data: dataDash, isLoading:isLoadingDash,isSuccess:isSuccessDash } = useGetDashboardQuery();
 const entries= [{name:'Total Sale', desc:dataDash?.total_sale,icon:'tag',clickable:true},{name:'Items', desc:dataDash?.ref_items,icon:'producthunt',clickable:false},{name:'Kits', desc:dataDash?.ref_kits,icon:'kit',clickable:false},{name:'Items not reviewed', desc:dataDash?.without_soyo_margin,icon:'error',clickable:false}];

  let list= entries.map((item,index)=>{
    return   <div  key={index} >
      <ListItem  desc={item.desc} name={item.name} icon={item.icon} />
     
 </div>
  });

  return (
    <>
  <Column>
  <Text
          className="not-italic text-gray_900 w-[auto]"
          variant="h4"
        >
        {title}
        </Text>
        <Text
          className="mt-[2px] sm:mt-[2px] md:mt-[3px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[9px] 3xl:mt-[12px] not-italic text-bluegray_300 w-[auto]"
          variant="body2"
        >
          Stats
        </Text>
       
        
        {list}
        <div className="hover:bg-gray_100 w-[20%] rounded-lg">
          <Text
            className="common-button ml-[10%] mt-[10px] sm:mt-[10px] md:mt-[13px] lg:mt-[16px] xl:mt-[19px] 2xl:mt-[22px] 3xl:mt-[26px]  not-italic text-blue_labex"
            variant="body3"
            onClick={seeMore}
          >
            See more
          </Text>
        </div>
  </Column>

      
    </>
  );
};
RevenueList.propTypes = {
  title:PropTypes.string,
  seeMore:PropTypes.func,
 
}
RevenueList.defaultProps = {
  title: "Sales Summary",
};
export default RevenueList;
