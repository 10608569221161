
import { Img } from 'components/Img';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import PropTypes from "prop-types";
import { faCartShopping, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from 'components/Text';


function Header({
  logo,homePath,menus,cart
}) {
  let menu= menus.map((item,index)=>{
    return item.title?  (
      item.dropdown?
      <NavDropdown 
      style={{backgroundColor:'rgba(40, 54, 96, 0.2)'}} 
      className='rounded-lg px-2 mx-1 my-[auto]' 
      key={index} 
      // title={item.title}
      title={<Text className='not-italic text-gray_900_b2 px-[auto] mx-2'>{item.title}</Text> } 
      id="navbarScrollingDropdown">
        
     { item.entries.map((entry,index)=>{
       return <NavDropdown.Item style={{backgroundColor:'rgba(40, 54, 96, 0.2)'}}  key={index} onClick={entry.route_path}>{entry.title}</NavDropdown.Item>
      })}
      </NavDropdown>
    :<Nav.Link style={{backgroundColor:'rgba(40, 54, 96, 0.2)'}} className='rounded-lg px-2 mx-1 my-[auto] h-[38px]'  key={index} onClick={item.route_path}> {<Text className='not-italic text-indigo_900 px-[auto] mx-2'>{item.title}</Text> }</Nav.Link>)
   : item.dropdown?  
   
   <NavDropdown style={{backgroundColor:'rgba(40, 54, 96, 0.2)'}} className='text-gray_900 rounded-lg px-2 mx-1 my-[auto]' key={index} title={<FontAwesomeIcon
    type="outline"
    className="px-[auto] mx-2"
    color="#233467"
    fontSize={21}
     onClick={item.route_path}
    width={40}
    icon={ item.icon} />} id="collasible-nav-dropdown">
        
   { item.entries.map((entry,index)=>{
     return <NavDropdown.Item  key={index} onClick={entry.route_path}>{entry.title}</NavDropdown.Item>
    })}
    </NavDropdown>:
   <Nav.Link className='text-gray_900 rounded-lg px-2 mx-1 my-[auto] h-[38px]' style={{backgroundColor:'rgba(40, 54, 96, 0.2)'}} key={index} onClick={item.route_path} > 
   {cart==null? <FontAwesomeIcon
      type="outline"
      className="px-[auto] mx-2"
      color="#233467"
      fontSize={21}
      onClick={item.route_path}
      width={40}
icon={ item.icon} /> : cart}
  </Nav.Link>
  });
  return (
    <Navbar collapseOnSelect expand="sm" className='mb-2 mx-[20px] lg:mx-[50px]' >
      {/* <Container> */}
        <Navbar.Brand href={homePath}>
       <Img
                  src="/images/img_myproject11.png"
                  className="h-[60px] sm:h-[70px] md:h-[70px] lg:h-[80px] xl:h-[90px] 2xl:h-[95px] 3xl:h-[100px] w-[auto]"
                  alt="Labex Logo"
                /> 
                </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto" style={{ maxHeight: '100px' }}
            navbarScroll>
         
            {menu}
         
            {/* <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
            <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          {/* <Nav>
            <Nav.Link href="#deets">More deets</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">
              Dank memes
            </Nav.Link>
          </Nav> */}
        </Navbar.Collapse>
      {/* </Container> */}
    </Navbar>
  );
}
Header.propTypes = {
  logo: PropTypes.string,
  homePath: PropTypes.string,
  menus: PropTypes.array,
  cart:PropTypes.object
}
Header.defaultProps = {
  logo: "images/img_myproject11.png",
  homePath: "/organization",
  cart:null,
  // menus: [{title:'Items', dropdown: false, route_path:()=>{}, icon:''},{title:'Orders', dropdown: true, entries:[{title: 'Offers', route_path:()=>{}},{title: 'Order History', route_path:()=>{}}], icon:''},{title:'', route_path:()=>{}, icon:faUser},{title:'', route_path:()=>{}, icon:faCartShopping}],
  menus: [{title:'Items', dropdown: false, route_path:()=>{}, icon:''},{title:'Orders', dropdown: true, entries:[{title: 'Offers', route_path:()=>{}},{title: 'Order History', route_path:()=>{}}], icon:''},{dropdown: true, title:'', entries:[{title: 'Account Setting', route_path:()=>{}},{title: 'Log Out', route_path:()=>{}}], icon:faUser},{title:'', route_path:()=>{}, icon:faCartShopping}],

  notification: ['Add this', 'tab1','h']
};
export default Header;