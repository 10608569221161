import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Pagination,
  Select,
} from "@mui/material";
import { Button, Column, Row, Text } from "components";
import { useNavigate } from "react-router-dom";
import Card from "../../../components/Card/index";
import Header from "components/Header/index.jsx";
import EditAccounts from "components/Modals/EditAccount/index.js";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import CreateAccount from "components/Modals/CreateAccount";
import ViewAccount from "components/Modals/ViewUsers";
import { useDispatch } from "react-redux";
import { logOut } from "features/auth/authSlice";
import {
  useDeleteUserMutation,
  useGetAllUsersQuery,
  useUpdateUserMutation,
} from "features/users/usersApiSlice";
import MaterialTable from "material-table";
import EnhancedTable from "components/TableEnhanced";
import {
  useDeleteInstitutionMutation,
  useGetInstitutionsQuery,
  useUpdateInstitutionMutation,
} from "features/institution/institutionApiSlice";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Footer from "components/Footer";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const ViewInstitutionsPage = () => {
  const navigate = useNavigate();
  const [x, setX] = React.useState();
  const [errMsg, setErrMsg] = useState("");
  const [idToDelete, setIdToDelete] = useState("");
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [q, setQ] = useState("");
  const [rowId, setRowId] = useState();

  const [deleteInstitution, { isLoadingDelete }] =
    useDeleteInstitutionMutation();

  const [updateInstitution, { isLoadingUpdate }] =
    useUpdateInstitutionMutation();
  const handleDelete = async (value) => {
    try {
      const insData = await deleteInstitution(rowId).unwrap();
      setErrMsg("");
      setSuccessMsg("Successfully deleted Institution");
    } catch (err) {
      if (!err) {
        setErrMsg("No Server Response");
      } else if (err.status === 400) {
        setErrMsg("Invalid Credentials");
      } else if (err.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Failed to delete Institution");
      }
    }
  };
  const {
    data: records,
    isLoading,
    isSuccess,
    isError,
    refetch,
    isFetching,
    error,
  } = useGetInstitutionsQuery();
  const editable = structuredClone(records);
  const selectedRow = React.useRef([]);
  const [successMessage, setSuccessMsg] = useState("");
  const handleClick = (rows) => {
    selectedRow.current = rows;
  };

  const columns = [
    {
      title: "ID",
      field: "id",
      align: "left",
      type: "numeric",
      editable: false,
      filterPlaceholder: "Filter",
    },
    {
      title: "Name",
      field: "name",
      align: "left",
      filterPlaceholder: "Filter",
      validate: (rowData) => {
        if (rowData.name === undefined || rowData.name == "") {
          return "Required";
        } else return true;
      },
    },
    {
      title: "Country",
      field: "country",
      align: "left",
      filterPlaceholder: "Filter",
      validate: (rowData) => {
        if (rowData.country === undefined || rowData.country == "") {
          return "Required";
        } else return true;
      },
    },
    {
      title: "City",
      field: "city",
      align: "left",
      filterPlaceholder: "Filter",
      validate: (rowData) => {
        if (rowData.city === undefined || rowData.city == "") {
          return "Required";
        } else return true;
      },
    },
    {
      title: "Email Address",
      field: "email",
      align: "left",
      editable: false,
      filterPlaceholder: "Filter",
      validate: (rowData) => {
        if (rowData.email === undefined || rowData.email === "") {
          return "Required";
        } else if (!rowData.email.includes("@" && ".")) {
          return "Enter valid Email Address";
        } else return true;
      },
    },

    {
      title: "Phone Number",
      field: "phone",
      align: "left",
      type: "numeric",
      filterPlaceholder: "Filter",
      validate: (rowData) => {
        if (rowData.phone == "") {
          return "Required";
        } else if (rowData.phone.length < 10 || rowData.phone.length > 10) {
          return "Enter a valid phone number";
        } else return true;
      },
    },
    {
      title: "Address",
      field: "address",
      align: "left",
      filterPlaceholder: "Filter",
      validate: (rowData) => {
        if (rowData.address === undefined || rowData.address == "") {
          return "Required";
        } else return true;
      },
    },

    {
      title: "Category",
      field: "category",
      align: "left",
      lookup: {
        //soyo: "Officer",
        supplier: "Supplier",
        laboratory: "Laboratory",
        organization: "Organization",
        //client:"Client",
        //admin: "Administrator",
      },
      filterPlaceholder: "Filter",
    },
  ];

  const [openDia, setOpenDia] = React.useState(false);
  const handleClickOpen = () => {
    setOpenDia(true);
  };
  const handleClickClose = () => {
    setOpenDia(false);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function chooseModal(idx, data) {
    switch (idx) {
      case 1:
        setX(<EditAccounts entries={data} />);
        break;
      case 2:
        setX(<CreateAccount />);
        break;
    }
  }
  var menu = [
    {
      title: "Accounts",
      dropdown: true,
      entries: [
        // {title: 'Add User', route_path:()=>{chooseModal(2);handleOpen()}},
        {
          title: "Users Management",
          route_path: () => {
            navigate("/dashboard/users");
          },
        },
        {
          title: "Institutions Management",
          route_path: () => {
            navigate("/dashboard/institutions");
          },
        },
      ],
      icon: "",
    },
    {
      dropdown: true,
      title: "",
      entries: [
        {
          title: "Account Setting",
          route_path: () => {
            navigate("/dashboard/account");
          },
        },
        {
          title: "Log Out",
          route_path: () => {
            dispatch(logOut());
            navigate("/");
          },
        },
      ],
      icon: faUser,
    },
  ];

  return (
    <>
      {isLoading && (
        <Box
          sx={{ position: "absolute", top: 0, width: "100%", height: "50px" }}
        >
          <LinearProgress className="bg-blue_labex" />
        </Box>
      )}
      <Modal
        open={open}
        sx={{ overflow: "fixed" }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {x ?? <EditAccounts />}
      </Modal>
      <Dialog
        open={openDia}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            border: "2px solid #ffffff",
            borderRadius: 3,
            boxShadow: 24,
          }}
          className="p-3"
        >
          <DialogTitle id="alert-dialog-title">
            <Text variant="h4">
              Are you sure you want to delete this institution? All the data
              associated with this institution will be removed.
            </Text>
          </DialogTitle>

          <DialogActions>
            <Button className="w-[15%]" onClick={handleClickClose}>
              No
            </Button>
            <Button
              variant="FillRed700cc"
              className="w-[15%]"
              onClick={() => {
                handleDelete();
                handleClickClose();
              }}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      <Header homePath="/dashboard" menus={menu} />
      <Column className="mx-[auto]  pb-[5rem] w-[90%] sm:w-[90%] md:w-[85%] lg:w-[85%] xl:w-[85%]">
        <Text variant="h1" className="not-italic text-gray_900 mb-[1%]">
          Institutions Management
        </Text>
        <Button
          onClick={() => {
            chooseModal(2);
            handleOpen();
          }}
          className="w-[20%] mb-[2%]"
        >
          Add Institution
        </Button>
        <MaterialTable
          editable={{
            onRowUpdate: (newRow, oldRow) =>
              new Promise(async (resolve, reject) => {
                try {
                  const insData = await updateInstitution({
                    id: oldRow.id,
                    name: newRow.name,
                    country: newRow.country,
                    city: newRow.city,
                    email: newRow.email,
                    phone: newRow.phone,
                    category: newRow.category,
                    address: newRow.address,
                  }).unwrap();
                  setErrMsg("");
                  setSuccessMsg("Successfully edited Institution");
                } catch (err) {
                  if (!err) {
                    setErrMsg("No Server Response");
                  } else if (err.status === 400) {
                    setErrMsg("Invalid Credentials");
                  } else if (err.status === 401) {
                    setErrMsg("Unauthorized");
                  } else {
                    setErrMsg("Failed to create a global item");
                  }
                }
                setTimeout(() => resolve(), 500);
              }),
            onRowDelete: (Row) =>
              new Promise(async (resolve, reject) => {
                setRowId(Row.id);
                setOpenDia(true);

                setTimeout(() => resolve(), 500);
              }),
          }}
          options={{
            showTitle: false,
            sorting: true,
            emptyRowsWhenPaging: false,
            search: true,
            filtering: true,
            pageSizeOptions: [5, 10, 15],
            exportButton: true,
            exportAllData: true,
            // selection: true,
            showSelectAllCheckbox: true,
            showTextRowsSelected: true,

            selectionProps: (rowData) => ({ color: "primary" }),
            headerSelectionProps: (header) => ({ color: "primary" }),
            searchFieldAlignment: "left",
            grouping: true,
            columnsButton: true,
            rowStyle: {
              background: "rgba(40, 54, 96, 0.2)",
              color: "#181c32",
            },

            headerStyle: {
              background: "rgba(40, 54, 96, 0.2)",
              color: "#181c32",
              fontWeight: "bolder",
            },
            filterRowStyle: { background: "rgba(40, 54, 96, 0.2)" },
            detailPanelColumnStyle: { background: "rgba(40, 54, 96, 0.2)" },
            actionsColumnIndex: -1,
          }}
          onSelectionChange={(e) => {
            handleClick(e);
          }}
          columns={columns}
          data={editable}
          icons={tableIcons}
          // actions={[
          //   {
          //     icon: AddBox,
          //     tooltip: "Add Institution",
          //     isFreeAction: true,
          //     onClick: (event) => {
          //       chooseModal(2);
          //       handleOpen();
          //     },
          //   },
          // ]}
        />
      </Column>
      <Footer />
    </>
  );
};

export default ViewInstitutionsPage;
