import React, { forwardRef, useEffect, useRef, useState } from "react";
import { colors, LinearProgress, Modal, Tab, Tabs, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

import {} from "@fortawesome/fontawesome-free-brands";
import { Button, Column, Row, Text } from "components";
import { useNavigate } from "react-router-dom";
import Card from "../../../components/Card/index";
import Header from "components/Header/index.jsx";
import { faBoxesStacked, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditItemsModal from "components/Modals/EditItem";
import AddItemsModal from "components/Modals/AddItem";
import NewOrder from "components/Modals/NewOrder";
import Cart from "components/Modals/Cart";
import { logOut } from "features/auth/authSlice";
import { useDispatch } from "react-redux";
import {
  useDeleteItemMutation,
  useGetItemsQuery,
  useUpdateItemMutation,
} from "features/item/itemApiSlice";
import { Box } from "@mui/system";
import MaterialTable from "material-table";
import Toast from "components/Toast";

import { useGetReferenceItemsQuery } from "features/item/referenceItemApiSlice";
import MyItemList from "./item";
import MyKitsList from "./kit";
import Footer from "components/Footer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}



const MyItemsPage = () => {
  const navigate = useNavigate();
  const [x, setX] = React.useState();
  const [id, setId] = React.useState();

  const { data, isLoading, isSuccess, isError, refetch, isFetching, error } =
    useGetItemsQuery();
  const editable = structuredClone(data);
  const { data: result } = useGetReferenceItemsQuery
();
  var lookupObject = result?.reduce(function (acc, cur, i) {
    acc[cur.id] = cur.name;

    return acc;
  }, {});

  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  function chooseModal(idx) {
    switch (idx) {
      case 1:
        setX(<NewOrder />);
        break;
      case 2:
        setX(<Cart />);
        break;
    }
  }
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [editItem, { isLoadingg }] = useUpdateItemMutation();
  const [deleteItem, { isLoadinggg }] = useDeleteItemMutation();
  const selectedRow = React.useRef([]);
  const [successMessage, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }
  const handleClick = (rows) => {
    selectedRow.current = rows;
  };

  
  function chooseModal(idx, element) {
    switch (idx) {
      case 1:
        setX(<EditItemsModal data={element} />);
        break;
      case 2:
        setX(<AddItemsModal />);
        break;
    }
  }
  var menu = [
    {
      title: "Items",
      dropdown: true,
      entries: [
        { title: "Add Item", route_path: () => navigate("/supplier/additem") },
        { title: "My Items", route_path: () => navigate("/supplier/myitems") },
        // {
        //   title: "Add Item",
        //   route_path: () => {
        //     chooseModal(2);
        //     handleOpen();
        //   },
        // },
      ],
      icon: "",
    },
    {
      dropdown: true,
      title: "",
      entries: [
        {
          title: "Account Setting",
          route_path: () => {
            navigate("/supplier/account");
          },
        },
        {
          title: "Log Out",
          route_path: () => {
            dispatch(logOut());
            navigate("/");
          },
        },
      ],
      icon: faUser,
    },
  ];
  var list = data?.forEach((element) => {
    return (
      <Card
        modal={false}
        icon={
          <FontAwesomeIcon
            type="outline"
            className="px-[auto]"
            color="#ffffff"
            fontSize={30}
            width={40}
            icon={faBoxesStacked}
          />
        }
        // row11={dataItem}
        // row12='Gofen'
        row42={element.price}
        row41={element.quantity}
        button2name="Edit"
        row31=""
        button2action={() => {
          chooseModal(1, element);
          handleOpen();
        }}
      />
    );
  });

  return (
    <>
      {isLoading && (
        <Box
          sx={{ position: "absolute", top: 0, width: "100%", height: "50px" }}
        >
          <LinearProgress className="bg-green_400" />
        </Box>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        sx={{ overflow: "scroll" }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {x ?? <AddItemsModal />}
      </Modal>
      <Header homePath="/supplier" menus={menu} />
      <Column className="mx-[auto]  pb-[5rem] w-[90%] sm:w-[90%] md:w-[80%] lg:w-[80%] xl:w-[80%]">
        <Row className="mb-[3%] content-end grid grid-cols-2">
          <Text variant="h1" className=" not-italic text-gray_900">
            My Items
          </Text>
        </Row>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
          aria-label=" auto tabs example"
        >
          <Tab label="Item" {...a11yProps(0)} />
          <Tab label="KIt" {...a11yProps(1)} />
        </Tabs>
        {/* </AppBar> */}
        <TabPanel value={value} index={0}>
          <MyItemList />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MyKitsList />
        </TabPanel>

      
      </Column>
      <Footer />
    </>
  );
};

export default MyItemsPage;
