import React from "react";
import PropTypes from "prop-types";
import {
  faTag,
  faBars,
  faMoneyBill,
  faBuilding,
  faVials,
  faBriefcase,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import {
  faProductHunt,
  faViadeoSquare,
} from "@fortawesome/fontawesome-free-brands";
import { Column } from "components/Column";
import { Text } from "components/Text";
import { Row } from "components/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ListItem({ name, desc, icon }) {
  function chooseIcon(icon) {
    switch (icon) {
      case "tag":
        return faTag;
        break;
      case "producthunt":
        return faProductHunt;
        break;
      case "category":
        return faBars;
        break;
      case "moneybill":
        return faMoneyBill;
        break;
      case "lab":
        return faVials;
        break;
      case "building":
        return faBuilding;
        break;
      case "kit":
        return faBriefcase;
        break;
      case "error":
        return faCircleExclamation;
        break;
    }
  }
  return (
    <Row className="mt-[7px] sm:mt-[7px] md:mt-[10px] lg:mt-[9px] xl:mt-[10px] 2xl:mt-[12px] 3xl:mt-[14px]">
      {/* <div  className= "text-[2rem] md:text-[1.8rem] lg:text-[2rem] px-[20px] xl:px-[20px] lg:px-[17px] sm:px-[10px] md:px-[15px]  rounded-lg justify-content-center w-[60px] md:w-[60px] lg:w-[65px] xl:w-[70px] 2xl:w-[80px] 3xl:w-[90px] py-[7px] sm:py-[5px] md:py-[7px] lg:py-[9px] xl:py-[12px] 2xl:py-[14px] 3xl:py-[27px] bg-green_400 ">         */}
      <div className="text-[2.3rem] sm:text-[1.6rem] md:text-[1.8rem] lg:text-[2rem] xl:text-[2rem] rounded-lg px-[20px] sm:px-[10px] md:px-[15px] lg:px-[18px] xl:px-[18px] 2xl:px-[24px] 3xl:px-[27px] py-[10px] sm:py-[5px] md:py-[7px] lg:py-[9px] xl:py-[12px] 2xl:py-[14px] 3xl:py-[27px] bg-blue_labex ">
        <FontAwesomeIcon
          className="px-[auto]"
          color="#ffffff"
          width={30}
          icon={chooseIcon(icon)}
        />
      </div>
      <Column className="ml-[10px] sm:ml-[9px] md:ml-[13px] lg:ml-[17px] xl:ml-[22px] 2xl:ml-[28px] 3xl:ml-[32px] mt-[12px] sm:mt-[4px] md:mt-[6px] lg:mt-[10px] xl:mt-[13px] 2xl:mt-[16px] 3xl:mt-[19px]">
        <Text className="not-italic text-bluegray_800 " variant="body2">
          {desc}
        </Text>
        <Text className="soldCounter" variant="body3">
          {name}
        </Text>
      </Column>
    </Row>
  );
}
ListItem.propTypes = {
  name: PropTypes.string,
  desc: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
};
ListItem.defaultProps = {
  name: "Sales",
  desc: "1K",
  icon: "product-hunt",
};
export default ListItem;
