import React, { useEffect, useState, useRef } from "react";

import { Column, Row, Stack, Text, Img, Input, Button } from "components";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, LinearProgress } from "@mui/material";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import Toast from "components/Toast";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";
import { logOut, setCredentials } from "../../../features/auth/authSlice";
import {
  useLoginMutation,
  useCheckPasswordMutation,
  useGetInfoQuery,
} from "../../../features/auth/authApiSlice";
import { PasswordInput } from "components/Password Input";
import Cookies from "universal-cookie";
import { useCreateKitMutation } from "features/item/itemApiSlice";
import useAuth from "hooks/useAuth";

const LoginPage = () => {
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const {
    data,
    isLoading: isLoadingInfo,
    isSuccess,
    isError,
    error,
  } = useGetInfoQuery();
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = () => {
    setShowPassword(!showPassword);
  };
  const check = async () => {
    if (localStorage.getItem("userToken") != null) {
      NavigateToHome(data?.role);
    }
  };
  const cookie = new Cookies();
  useEffect(() => {
    check();
  }, [data]);
  function NavigateToHome(role) {
    switch (role) {
      case "admin":
        navigate("/dashboard");
        break;
      case "client":
        navigate("/organization");
        break;
      case "supplier":
        navigate("/supplier");
        break;
      case "soyo":
        navigate("/officer");
        break;
      // default:
      //   dispatch(logOut());
      //   break;
    }
  }

  function handleNavigate3() {
    navigate("/forgotpassword");
  }
  const loginSchema = Yup.object().shape({
    username: Yup.string()
      .required("Username is required")
      .min(2, "Username is too Short!")
      .max(50, "Username is too Long!"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum"),
  });
  return (
    <>
      {isLoading && (
        <Box
          sx={{ position: "absolute", top: 0, width: "100%", height: "50px" }}
        >
          <LinearProgress className="bg-blue_labex" />
        </Box>
      )}

      <Column className="grid items-center grid-cols-1 justify-items-center md:grid-cols-2">
        <div className="bg-bluegray_200 h-[50vh] md:h-[100vh] grid ">
          <Img
            // src="/images/labexafrica_logo.png"
            src="/images/labex-logo.svg"
            className="lg:h-[200px] xl:h-[250px] w-[100vh] 2xl:h-[211px] 3xl:h-[253px]  my-[auto] object-scale-down"
            alt="Logo"
          />
          {/* <Text
            className="not-italic text-center self-start text-white_A700 leading-[26px] lg:leading-[32px] xl:leading-[40px] 2xl:leading-[45px] 3xl:leading-[54px]"
            as="h3"
            variant="h3"
          >
            
          </Text> */}
        </div>
        <div className="h-[50vh] md:h-[100vh] w-[80%] sm:w-[70%] md:w-[60%] mx-[auto] py-[10%] sm:py-[5%] md:py-[40%] lg:pt-[35%] xl:pt-[30%] ">
          <Text
            className="not-italic text-gray_900 w-[100%] text-center inline mx-[auto] mb-[10%]"
            as="h1"
            variant="h1"
          >
            Welcome to<span style={{ display:'inline'}}>
              <Img
            
            src="/images/labex-logo.svg"
            className="lg:h-[70px] h-[60px] inline object-scale-down"
            alt="Logo"
          /></span>
          </Text>
          <br></br>
          <Text
            className="sm:mt-[30px] lg:mt-[49px] xl:mt-[61px] 2xl:mt-[69px] 3xl:mt-[82px] not-italic text-gray_900 w-[auto]"
            variant="body1"
          >
            Username
          </Text>

          <Formik
            initialValues={{ username: "", password: "" }}
            validationSchema={loginSchema}
            onSubmit={async (values, { resetForm }) => {
              try {
                const userData = await login({
                  username: values.username,
                  password: values.password,
                }).unwrap();
                setErrMsg("");
                localStorage.setItem("userToken", userData.token);

                // cookie.set("userToken", userData.token, {
                //   //secure: true,
                //   httpOnly: true,
                //   expires: new Date(2030, 1, 1),
                // });
                // document.cookie =  "id=a3fWa; Expires=Thu, 21 Oct 2021 07:28:00 GMT; Secure; HttpOnly";
                localStorage.setItem("userId", userData.id);
                //localStorage.setItem("userRole", userData.role);
                dispatch(setCredentials({ ...userData }));
                //resetForm();
                NavigateToHome(userData.role);
              } catch (err) {
                if (!err) {
                  setErrMsg("No Server Response");
                } else if (err.status === 400) {
                  setErrMsg("Invalid Credentials");
                } else if (err.status === 401) {
                  setErrMsg("Unauthorized");
                } else {
                  setErrMsg("Login Failed");
                }
              }
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                {errMsg && <Toast message={errMsg} />}
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px]  flex h-[30px] mt-[6px] sm:h-[36px] sm:mt-[7px] md:h-[40px] md:mt-[8px] lg:h-[40px] lg:mt-[9px] w-[100%] xl:h-[45px] xl:mt-[10px]"
                  name="username"
                  id="username"
                  {...formik.getFieldProps("username")}
                  placeholder="Username"
                ></Input>
                {formik.touched?.username && formik.errors?.username ? (
                  <div>
                    <Text
                      className="not-italic text-red_700_cc w-[auto]"
                      variant="body2"
                    >
                      {formik.errors.password}
                    </Text>
                  </div>
                ) : null}
                <Row className="justify-between mt-[16px] sm:mt-[18px] md:mt-[20px] lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[40px] w-[100%]">
                  <Text
                    className="not-italic text-gray_900 w-[auto]"
                    variant="body1"
                  >
                    Password
                  </Text>
                  <Text
                    className="common-pointer mt-[1px] not-italic text-blue_300 w-[auto]"
                    variant="body2"
                    onClick={handleNavigate3}
                  >
                    Forgot Password?
                  </Text>
                </Row>
                <PasswordInput
                  id="password"
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px]  flex h-[30px] mt-[6px] sm:h-[36px] sm:mt-[7px] md:h-[40px] md:mt-[8px] lg:h-[40px] lg:mt-[9px] w-[100%] xl:h-[45px] xl:mt-[10px]"
                  name="password"
                  placeholder="Password"
                  {...formik.getFieldProps("password")}
                  type={showPassword ? "text" : "password"}
                  suffix={
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  }
                ></PasswordInput>
                {formik.touched?.password && formik.errors?.password ? (
                  <div>
                    {" "}
                    <Text
                      className="not-italic text-red_700_cc w-[auto]"
                      variant="body2"
                    >
                      {formik.errors.password}
                    </Text>
                  </div>
                ) : null}
                <Button
                  type="submit"
                  className="w-[100px] mx-[auto] mt-4"
                  disabled={isLoading}
                >
                  Log In
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </Column>
    </>
  );
};

export default LoginPage;
