import React, { useEffect, useState, useRef } from "react";

import { Column, Row, Stack, Text, Img, Input, Button } from "components";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import * as Yup from "yup";
import { Form, Formik, Field } from "formik";
import Toast from "components/Toast";

import { useDispatch } from "react-redux";
import { useForgotPasswordResetMutation, useResetPasswordMutation } from "features/auth/authApiSlice";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { PasswordInput } from "components/Password Input";
const ResetPasswordPage = ({ match, location }) => {
  const searchParams = new URLSearchParams(document.location.search);

  const [resetPass, { isLoading }] = useResetPasswordMutation();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = () => {
    setShowPassword(!showPassword);
  };
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();

  const initialValues = {
    password1: "",
    password2: "",
  };
  const validationSchema = Yup.object().shape({
    password1: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be 8 chars minimum"),
    password2: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be 8 chars minimum"),
  });
  return (
    <>
      <Column className="grid items-center grid-cols-1 md:grid-cols-2">
      <div className="bg-bluegray_200 h-[50vh] md:h-[100vh] grid ">
          <Img
                      src="/images/labex-logo.svg"
            className="lg:h-[200px] xl:h-[250px] 2xl:h-[211px] 3xl:h-[253px] w-[100%] my-[auto] object-scale-down"
            alt="Logo"
          />
        </div>
        <div className="h-[50vh] md:h-[100vh] items-center w-[80%] sm:w-[70%] md:w-[60%] mx-[auto] py-[10%] sm:py-[5%] md:py-[40%] lg:pt-[30%] xl:pt-[25%] justify-center  justify-items-center">
          <Text
            className="not-italic text-gray_900 w-[auto] text-center mx-[auto] mb-[10%]"
            as="h1"
            variant="h1"
          >
            Set New Password
          </Text>
          {/* <Text
              className="text-center leading-[18px] lg:leading-[18px] xl:leading-[23px] 2xl:leading-[26px] 3xl:leading-[31px] mt-[5px] lg:mt-[10px] xl:mt-[13px] 2xl:mt-[15px] 3xl:mt-[18px] not-italic text-bluegray_300 w-[auto]"
              as="h6"
              variant="h6"
            >
              Your new password must be different from previously used ones.
            </Text> */}
          {errMsg && <Toast message={errMsg} />}

          <Formik
            initialValues={initialValues}
             validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
             
                if(values.password1 == values.password2){
                    try {
                        
                         
                              const pass = await resetPass({
                                password: values.password1,
                                param:  `uidb64=${searchParams.get("uidb64")}&token=${searchParams.get("token")}`,
                            
                              }).unwrap();
                              setErrMsg("");
                              navigate('/');
                            //   resetForm();
                            
                          } catch (err) {
                            if (!err) {
                              setErrMsg("No Server Response");
                            }else if(err.data){
                                setErrMsg(err.data.error);
                            }
                             else if (err.status === 400) {
                              setErrMsg("Invalid Credentials");
                            } else if (err.status === 401) {
                              setErrMsg("Unauthorized");
                            } else {
                              setErrMsg("Failed to set a password");
                            }
                          }
                  }else setErrMsg("Your passwords do not match");
              }
               
              
            
              //   
            }
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Column className="gap-2 md:gap-4 mt-[10px]">
                  <Text
                    className="not-italic text-gray_900 w-[auto]"
                    variant="body1"
                  >
                    New Password
                  </Text>
                  <PasswordInput
                    className="w-[100%]"
                    wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px]  flex h-[30px] mt-[6px] sm:h-[36px] sm:mt-[7px] md:h-[40px] md:mt-[8px] lg:h-[40px] lg:mt-[9px] w-[100%] xl:h-[45px] xl:mt-[10px]"
                    name="password1"
                    // ref={userRef}
                    // value={user}
                    id="password1"
                    type={showPassword ? "text" : "password"}
                    suffix={
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    }
                    {...formik.getFieldProps("password1")}
                  ></PasswordInput>
                  {formik.touched?.password1 && formik.errors?.password1 ? (
                <div>
                  <Text className="not-italic text-red_700_cc " variant="body2">
                    {formik.errors.password1}
                  </Text>
                </div>
              ) : null}
                  <Text
                    className="not-italic text-gray_900 w-[auto]"
                    variant="body1"
                  >
                    Confirm Password
                  </Text>

                  <PasswordInput
                    id="password2"
                    className="w-[100%]"
                    wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px]  flex h-[30px] mt-[6px] sm:h-[36px] sm:mt-[7px] md:h-[40px] md:mt-[8px] lg:h-[40px] lg:mt-[9px] w-[100%] xl:h-[45px] xl:mt-[10px]"
                    name="password2"
                    type={showPassword ? "text" : "password"}
                    suffix={
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    }
                    {...formik.getFieldProps("password2")}
                  ></PasswordInput>
{formik.touched?.password2 && formik.errors?.password2 ? (
                <div>
                  <Text className="not-italic text-red_700_cc " variant="body2">
                    {formik.errors.password2}
                  </Text>
                </div>
              ) : null}
                  <Button
                    type="submit"
                    className="w-[auto] mx-[auto] mt-4"
                    children={<Text>Reset Password</Text>}
                  ></Button>
                </Column>
              </form>
            )}
          </Formik>
        </div>
      </Column>
    </>
  );
};

export default ResetPasswordPage;
