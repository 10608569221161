import {
  Box,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Input, Column, Text, Button, Radio, RadioGroup } from "components/";
import { Row } from "components/Row";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import {
  useCreateGlobalItemMutation,
  useGetGlobalItemsQuery,
  useGetReferenceItemsQuery,
} from "features/item/referenceItemApiSlice";
import Toast from "components/Toast";
import MultipleSelectInput from "components/MultipleSelection";
import PropTypes from "prop-types";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import { useCreateReferenceKitMutation } from "features/item/referenceKitApiSlice";

function KitTab() {
  const [errMsg, setErrMsg] = useState("");
  const [addKit, { isLoading }] = useCreateReferenceKitMutation();
  const [successMessage, setSuccessMsg] = useState("");
  const [itemsList, setItemsList] = useState("");
  const { data, isLoadingg, isSuccess, isError, refetch, isFetching, error } =
    useGetReferenceItemsQuery();
  const initialValues = {
    items: { name: "", id: null, description: "" },
  };

  const validationSchema = Yup.object().shape({
    kitname: Yup.string().required("Item Name is required"),
    description: Yup.string()
      .required("Description is required")
      .max(60, "Description is too long"),
    items: Yup.array().required("This is a required field."),
  });
  return (
    <div style={{ overflow: "scroll", height: "50vh" }}>
      {isLoadingg && (
        <Box
          sx={{ position: "absolute", top: 0, width: "100%", height: "50px" }}
        >
          <LinearProgress className="bg-blue_labex" />
        </Box>
      )}

      <Text className="not-italic text-gray_900 w-[auto]" variant="h2">
        Create Reference Kit
      </Text>
      <Formik
        initialValues={{ kitname: "", description: "", items: [] }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          var itemIds = [];
          values.items?.map((item, index) => itemIds.push(item.id));
          try {
            const itemData = await addKit({
              name: values.kitname,
              description: values.description,
              items: itemIds,
            }).unwrap();
            setErrMsg("");
            setSuccessMsg("Successfully added a global kit");
            resetForm();
          } catch (err) {
            if (!err) {
              setErrMsg("No Server Response");
            } else if (err.status === 400) {
              setErrMsg("Invalid Credentials");
            } else if (err.status === 401) {
              setErrMsg("Unauthorized");
            } else {
              setErrMsg("Failed to create a global item");
            }
          }
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Column className="gap-2 mt-[10px]">
              {errMsg && <Toast message={errMsg} />}
              {successMessage && (
                <Toast type="success" message={successMessage} />
              )}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text
                  className="not-italic text-gray_900 w-[150px]"
                  variant="h6"
                >
                  Kit Name
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px]  sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="kitname"
                  {...formik.getFieldProps("kitname")}
                ></Input>
              </Row>
              {formik.touched?.kitname && formik.errors?.kitname ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.kitname}
                  </Text>
                </div>
              ) : null}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text
                  className="not-italic text-gray_900 w-[150px]"
                  variant="h6"
                >
                  Kit Description
                </Text>
                <Input
                  className="diasable w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px]  sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="description"
                  {...formik.getFieldProps("description")}
                ></Input>
              </Row>
              {formik.touched?.description && formik.errors?.description ? (
                <div>
                  <Text className="not-italic text-red_700_cc " variant="body2">
                    {formik.errors.description}
                  </Text>
                </div>
              ) : null}

              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text
                  className="not-italic text-gray_900 w-[150px]"
                  variant="h6"
                >
                  Items
                </Text>
                <Autocomplete
                  sx={
                    {
                      // backgroundColor: "green",
                    }
                  }
                  multiple
                  onChange={(e, value) => {
                    formik.setFieldValue(
                      "items",
                      value !== null ? value : initialValues.items
                    );
                  }}
                  id="items"
                  name="items"
                  options={data ? data : []}
                  // {...formik.getFieldProps('i')}

                  className="w-[160px] h-[300px] bg-gray_50 border border-gray_50 border-solid rounded-radius845 sm:w-[300px]"
                  getOptionLabel={(option) => option.name}
                  // defaultValue={data??data[1]}
                  // inputValue={null}
                  renderInput={(params) => (
                    <TextField
                      className="h-[100px] px-3 "
                      {...params}
                      variant="standard"
                      placeholder="Items to add"
                      name="items"
                      // color="#456686"
                    />
                  )}
                />
              </Row>

              <Button type="submit" className="w-[200px] mt-4">
                {" "}
                Add Global Kit{" "}
              </Button>
            </Column>
          </form>
        )}
      </Formik>
    </div>
  );
}
export default KitTab;
