import React, { forwardRef, useEffect, useRef, useState } from "react";
import { colors, LinearProgress, Modal, Tab, Tabs, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

import {} from "@fortawesome/fontawesome-free-brands";
import { Button, Column, Row, Text } from "components";
import { useNavigate } from "react-router-dom";
import Card from "../../../components/Card/index";
import Header from "components/Header/index.jsx";
import { faBoxesStacked, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditItemsModal from "components/Modals/EditItem";
import AddItemsModal from "components/Modals/AddItem";
import NewOrder from "components/Modals/NewOrder";
import Cart from "components/Modals/Cart";
import { logOut } from "features/auth/authSlice";
import { useDispatch } from "react-redux";
import {
  useDeleteItemMutation,
  useGetItemsQuery,
  useUpdateItemMutation,
} from "features/item/itemApiSlice";
import { Box } from "@mui/system";
import MaterialTable from "material-table";
import Toast from "components/Toast";

import { Close, Rowing } from "@material-ui/icons";
import { green } from "@mui/material/colors";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useGetReferenceItemsQuery } from "features/item/referenceItemApiSlice";
import AddItemList from "./item";
import AddKitsList from "./kit";
import { BounceLoader } from "react-spinners";
import Footer from "components/Footer";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
const AddItemPage = () => {
  const navigate = useNavigate();
  const [x, setX] = React.useState();
  const [id, setId] = React.useState();



  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();


  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }



  var menu = [
    {
      title: "Items",
      dropdown: true,
      entries: [
        { title: "Add Item", route_path: () => navigate("/supplier/additem") },

        { title: "My Items", route_path: () => navigate("/supplier/myitems") },
        // {
        //   title: "Add Item",
        //   route_path: () => {
        //     chooseModal(2);
        //     handleOpen();
        //   },
        // },
      ],
      icon: "",
    },
    {
      dropdown: true,
      title: "",
      entries: [
        {
          title: "Account Setting",
          route_path: () => {
            navigate("/supplier/account");
          },
        },
        {
          title: "Log Out",
          route_path: () => {
            dispatch(logOut());
            navigate("/");
          },
        },
      ],
      icon: faUser,
    },
  ];


  return (
    <>
      

     
      <Header homePath="/supplier" menus={menu} />
      <Column className="mx-[auto]  pb-[5rem] w-[90%] sm:w-[90%] md:w-[80%] lg:w-[80%] xl:w-[80%]">
   
        <Row className="mb-[3%] content-end grid grid-cols-2">
        <Column>
        <Text variant="h1" className="not-italic text-gray_900">
            Add Item to Inventory
          </Text>
        <Text
          className="mt-[2px] sm:mt-[2px] md:mt-[3px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[9px] 3xl:mt-[12px] not-italic text-bluegray_300 w-[auto]"
          variant="body2"
        >
        Select the row of your choice to add Product to inventory
        </Text>
        </Column>
        </Row>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
          aria-label=" auto tabs example"
        >
          <Tab label="Item" {...a11yProps(0)} />
          <Tab label="KIt" {...a11yProps(1)} />
        </Tabs>
        {/* </AppBar> */}
        <TabPanel value={value} index={0}>
          <AddItemList />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AddKitsList />
        </TabPanel>
        {/* <ThemeProvider theme={theme}> */}
         
        {/* </ThemeProvider> */}
      </Column>
      <Footer />
    </>
  );
};

export default AddItemPage;
