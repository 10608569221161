import {
  Box,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import { Input, Column, Text, Button, Radio, RadioGroup } from "components/";
import { Row } from "components/Row";
import Toast from "components/Toast";
import { useGetInstitutionsQuery } from "features/institution/institutionApiSlice";
import { useUpdateUserMutation } from "features/users/usersApiSlice";
import { Formik, Form } from "formik";
import React, { useState } from "react";
function EditAccounts({ entries }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #ffffff",
    borderRadius: 5,
    boxShadow: 24,
    px: 5,
    py: 5,
  };
  const [errMsg, setErrMsg] = useState("");
  const [successMessage, setSuccessMsg] = useState("");
  const [institution, setInstitution] = useState(entries?.institution);
  const [firstName, setFirstName] = useState(entries?.first_name);
  const [lastName, setLastName] = useState(entries?.last_name);
  const [email, setEmail] = useState(entries?.email);
  const [role, setRole] = useState(entries?.role);
  const [phonenumber, setPhoneNumber] = useState(entries?.phone);
  const [position, setPosition] = useState(entries?.position);
  const { data, isLoadingg, isSuccess, isError, error } =
    useGetInstitutionsQuery();
  const [updateUser, { isLoading }] = useUpdateUserMutation();

  let list = data?.map((item, index) => {
    return <MenuItem value={item.id}>{item.name}</MenuItem>;
  });
  function handleInputChanges(e) {
    switch (e.target.name) {
      case "firstName":
        setFirstName(e.target.value);
        break;
      case "lastName":
        setLastName(e.target.value);
        break;
      case "emailaddress":
        setEmail(e.target.value);
        break;
      case "phonenumber":
        setPhoneNumber(e.target.value);
        break;
      case "role":
        setRole(e.target.value);
        break;
      case "institution":
        setInstitution(e.target.value);
        break;
      case "position":
        setPosition(e.target.value);
        break;
    }
  }
  function clearInputs() {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setInstitution("");
    setRole("");
    setPosition("");
  }
  const handleSubmit = async (values) => {
    try {
      const userData = await updateUser({
        id: entries?.id,
        username: entries?.username,
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phonenumber,
        role: role,
        institution: institution,
        position: position,
      }).unwrap();
      setErrMsg("");
      setSuccessMsg("Successfully updated user " + firstName + " " + lastName);
      // clearInputs();
    } catch (err) {
      if (!err) {
        setErrMsg("No Server Response");
      } else if (err.status === 400) {
        setErrMsg("Invalid Credentials");
      } else if (err.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
    }
  };
  return (
    <Box className="w-[60%] md:w-[600px]" sx={style}>
      <div style={{ overflow: "scroll", height: "70vh" }}>
        {true && (
          <Box
            sx={{ position: "absolute", top: 0, width: "100%", height: "50px" }}
          >
            <LinearProgress className="bg-blue_labex" />
          </Box>
        )}
        {errMsg && <Toast message={errMsg} />}
        {successMessage && <Toast type="success" message={successMessage} />}
        <Text className="not-italic text-gray_900 w-[auto]" variant="h2">
          Edit Account
        </Text>
        <Formik

        // validationSchema={loginSchema}
        // onSubmit={handleSubmit}
        >
          {(props) => (
            <div component={Form} noValidate>
              <Column className="gap-3 mt-[30px]">
                <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px]"
                    variant="h6"
                  >
                    First Name
                  </Text>
                  <Input
                    className="w-[100%]"
                    wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                    name="firstName"
                    value={firstName}
                    onChange={handleInputChanges}
                  ></Input>
                </Row>
                <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px]"
                    variant="h6"
                  >
                    Last Name
                  </Text>
                  <Input
                    className="w-[100%]"
                    wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                    name="lastName"
                    value={lastName}
                    onChange={handleInputChanges}
                  ></Input>
                </Row>
                <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px]"
                    variant="h6"
                  >
                    Email Address
                  </Text>
                  <Input
                    className="w-[100%]"
                    wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                    name="emailaddress"
                    value={email}
                    onChange={handleInputChanges}
                  ></Input>
                </Row>
                <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px]"
                    variant="h6"
                  >
                    Phone Number
                  </Text>
                  <Input
                    className="w-[100%]"
                    wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                    name="phonenumber"
                    value={phonenumber}
                    onChange={handleInputChanges}
                  ></Input>
                </Row>
                <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px]"
                    variant="h6"
                  >
                    Category
                  </Text>

                  <Select
                    className="border-gray_50 border-solid bg-gray_50  w-[200px] sm:w-[300px] lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] xl:h-[41px] xl:mt-[8px]"
                    id="role"
                    label="Role"
                    name="role"
                    value={role}
                    onChange={handleInputChanges}
                  >
                    <MenuItem value="soyo">Labex Officer</MenuItem>
                    <MenuItem value="admin">Administrator</MenuItem>
                    <MenuItem value="client">Laboratory/Organization</MenuItem>
                    <MenuItem value="supplier">Supplier</MenuItem>
                  </Select>
                </Row>
                <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px]"
                    variant="h6"
                  >
                    Position
                  </Text>
                  <Input
                    className="w-[100%]"
                    wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                    name="position"
                    value={position}
                    onChange={handleInputChanges}
                  ></Input>
                </Row>
                <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px]"
                    variant="h6"
                  >
                    Institution
                  </Text>

                  <Select
                    className="RoundedBorder8 border-gray_50 border-solid bg-gray_50  w-[200px] sm:w-[300px] lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] xl:h-[41px] xl:mt-[8px]"
                    labelId="inst"
                    placeholder="Institution"
                    id="inst"
                    value={institution}
                    label="Age"
                    name="institution"
                    onChange={handleInputChanges}
                  >
                    {list}
                  </Select>
                </Row>

                <Button
                  onClick={() => handleSubmit()}
                  type="submit"
                  className="w-[200px] mt-4"
                  children={<Text>Edit User</Text>}
                ></Button>
              </Column>
            </div>
          )}
        </Formik>
      </div>
    </Box>
  );
}

export default EditAccounts;
