import { Input, Text, Button, Column } from "components/";
import { Row } from "components/Row";
import { Formik, Form } from "formik";
import React, { useCallback, useRef, useState } from "react";
import * as Yup from "yup";
import {
  useCreateClientMutation,
  useCreateInstitutionMutation,
} from "features/institution/institutionApiSlice";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import Toast from "components/Toast";
import { LocationCityRounded } from "@material-ui/icons";
import { Mapp } from "components/Map";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import SelectLocation from "components/Mappp";

function ClientTabComponent() {
  const [errMsg, setErrMsg] = useState("");
  const [successMessage, setSuccessMsg] = useState("");
  const [createClient, { isLoading }] = useCreateClientMutation();
  const [useMap, setUseMapp] = useState(true);

  const mapRef = useRef();

  const onLoad = useCallback((map) => (mapRef.current = map), []);
  const validationSchema = Yup.object().shape({
    // item: Yup.object().required("Item Required"),
    // brand: Yup.string().required("Brand Required"),
    name: Yup.string().required("Required"),
    phone: Yup.string()
      .required("Required")
      .min(10, "Must be a valid phone number"),
    country: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    email: Yup.string().email().required("Valid Email Required"),
    address: Yup.string().required("Required"),
    billingAddress: Yup.string().required("Required"),
    shippingAddress: Yup.string(),
    customProcess: Yup.string().required("Required"),
    customBrokerName: Yup.string().required("Required"),
    shippingNotification: Yup.string().required("Required"),
    role: Yup.string().required("Required"),
  });
  const initialValues = {
    name: "",
    phone: "",
    country: "",
    city: "",
    email: "",
    address: "",
    billingAddress: "",
    shippingAddress: "",
    customProcess: "",
    customBrokerName: "",
    shippingNotification: "",
    role: "",
    location_lat: 9.0013235,
    location_lon: 38.7670247,
    location_name:
      "Rizq Building | Gazebo Square | ሪዝቅ ህንጻ | ጋዜቦ አደባባይ, Addis Ababa, Ethiopia",
  };

  return (
    <div style={{ overflow: "scroll", height: "50vh" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            const userData = await createClient({
              name: values.name,
              country: values.country,
              city: values.city,
              email: values.email,
              phone: values.phone,
              address: values.address,
              billing_address: values.billingAddress,
              shipping_address: useMap
                ? values.location_name
                : values.shippingAddress,
              custom_process: values.customProcess,
              custom_broker_name: values.customBrokerName,
              shipping_notification: values.shippingNotification,
              category: values.role,
              shipping_location: {
                latitude: values.location_lat,
                longitude: values.location_lon,
              },
            }).unwrap();
            setErrMsg("");
            setSuccessMsg("Successfully added a client");
            resetForm();
          } catch (err) {
            if (!err) {
              setErrMsg("No Server Response");
            } else if (err.data?.shipping_address)
              setErrMsg("Shipping address can not be empty");
            else if (err.status === 400) {
              setErrMsg("Invalid Credentials");
            } else if (err.status === 401) {
              setErrMsg("Unauthorized");
            } else {
              setErrMsg("Failed to create an item");
            }
          }
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Column className="gap-2">
              {isLoading && (
                <Box
                  sx={{
                    position: "relative",
                    top: 0,
                    width: "100%",
                    height: "50px",
                  }}
                >
                  <LinearProgress className="bg-blue_labex" />
                </Box>
              )}
              {errMsg && <Toast message={errMsg} />}
              {successMessage && (
                <Toast type="success" message={successMessage} />
              )}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text className="not-italic text-gray_900 " variant="h6">
                  Name
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="name"
                  // value={name}
                  {...formik.getFieldProps("name")}
                ></Input>
              </Row>
              {formik.touched?.name && formik.errors?.name ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.name}
                  </Text>
                </div>
              ) : null}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text className="not-italic text-gray_900 " variant="h6">
                  Phone Number
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="phone"
                  // value={phone}
                  {...formik.getFieldProps("phone")}
                ></Input>
              </Row>
              {formik.touched?.phone && formik.errors?.phone ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.phone}
                  </Text>
                </div>
              ) : null}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text className="not-italic text-gray_900" variant="h6">
                  Email Address
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="email"
                  // value={email}
                  // onChange={handleInputChanges}
                  {...formik.getFieldProps("email")}
                ></Input>
              </Row>
              {formik.touched?.email && formik.errors?.email ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.email}
                  </Text>
                </div>
              ) : null}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text className="not-italic text-gray_900 " variant="h6">
                  Address
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flexlg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="address"
                  // value={address}
                  // onChange={handleInputChanges}
                  {...formik.getFieldProps("address")}
                ></Input>
              </Row>
              {formik.touched?.address && formik.errors?.address ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.address}
                  </Text>
                </div>
              ) : null}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text className="not-italic text-gray_900 " variant="h6">
                  Country
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="country"
                  // value={country}
                  // onChange={handleInputChanges}
                  {...formik.getFieldProps("country")}
                ></Input>
              </Row>
              {formik.touched?.country && formik.errors?.country ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.country}
                  </Text>
                </div>
              ) : null}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text className="not-italic text-gray_900 " variant="h6">
                  City
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="city"
                  // value={city}
                  // onChange={handleInputChanges}
                  {...formik.getFieldProps("city")}
                ></Input>
              </Row>
              {formik.touched?.city && formik.errors?.city ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.city}
                  </Text>
                </div>
              ) : null}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text
                  className="not-italic text-gray_900 w-[150px]"
                  variant="h6"
                >
                  Category
                </Text>
                {/* <Input
              className="w-[100%]"
              wrapClassName=" 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] lg:mt-[6px] w-[60%] xl:h-[41px] xl:mt-[8px]"
              name="category"
              placeholder="Category"
              disabled='true'
            ></Input> */}
                <select
                  // color="secondary"
                  className="px-3 bg-gray_50 2xl:mt-[9px]  3xl:mt-[10px] flex 3xl:h-[56px] 2xl:h-[47px] xl:h-[41px] lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[160px]  sm:w-[300px]  xl:mt-[8px] border border-gray_50 border-solid rounded-radius845"
                  id="role"
                  label="Role"
                  name="role"
                  // value={role}
                  // onChange={handleInputChanges}
                  {...formik.getFieldProps("role")}
                  // value={data?.data.brand}
                  // {...formik.getFieldProps("brand")}
                >
                  <option></option>

                  <option value="laboratory">Laboratory</option>

                  <option value="organization">Organization</option>
                </select>
                {/* <Select
                  className="border-gray_50 border-solid bg-gray_50  w-[200px] sm:w-[300px] lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] xl:h-[41px] xl:mt-[8px]"
                  id="role"
                  label="Role"
                  name="role"
                  // value={role}
                  // onChange={handleInputChanges}
                  {...formik.getFieldProps("role")}
                >
                  <MenuItem value="laboratory">Laboratory</MenuItem>
                  <MenuItem value="organization">Organization</MenuItem>
                </Select> */}
              </Row>
              {formik.touched?.role && formik.errors?.role ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.role}
                  </Text>
                </div>
              ) : null}

              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text className="not-italic text-gray_900 " variant="h6">
                  Billing Address
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="billingAddress"
                  // value={billingAddress}
                  // onChange={handleInputChanges}
                  {...formik.getFieldProps("billingAddress")}
                ></Input>
              </Row>
              {formik.touched?.billingAddress &&
              formik.errors?.billingAddress ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.billingAddress}
                  </Text>
                </div>
              ) : null}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text className="not-italic text-gray_900 " variant="h6">
                  Custom Process
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="customProcess"
                  // value={customProcess}
                  // onChange={handleInputChanges}
                  {...formik.getFieldProps("customProcess")}
                ></Input>
              </Row>
              {formik.touched?.customProcess && formik.errors?.customProcess ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.customProcess}
                  </Text>
                </div>
              ) : null}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text className="not-italic text-gray_900 " variant="h6">
                  Custom Broker Name
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="customBrokerName"
                  // value={customBrokerName}
                  // onChange={handleInputChanges}
                  {...formik.getFieldProps("customBrokerName")}
                ></Input>
              </Row>
              {formik.touched?.customBrokerName &&
              formik.errors?.customBrokerName ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.customBrokerName}
                  </Text>
                </div>
              ) : null}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text className="not-italic text-gray_900 " variant="h6">
                  Shipping Notification
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="shippingNotification"
                  // value={shippingNotification}
                  // onChange={handleInputChanges}
                  {...formik.getFieldProps("shippingNotification")}
                ></Input>
              </Row>
              {formik.touched?.shippingNotification &&
              formik.errors?.shippingNotification ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.shippingNotification}
                  </Text>
                </div>
              ) : null}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text className="not-italic text-gray_900 " variant="h6">
                  Shipping Address
                </Text>
                <Input
                  className="w-[100%]"
                  disabled={useMap}
                  wrapClassName={
                    useMap
                      ? "bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                      : "2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  }
                  name="shippingAddress"
                  // value={shippingAddress}
                  {...formik.getFieldProps("shippingAddress")}
                ></Input>
              </Row>

              <Row className="items-center grid grid-cols-1">
                <FormGroup className="ml-[4%]">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setUseMapp(e.target.checked)}
                        defaultChecked
                      />
                    }
                    label="Use the location name on the Map as my Shipping Address"
                  />
                </FormGroup>
              </Row>
              <SelectLocation disabled={true} />
              <Button
                // onClick={() => handleSubmit()}
                type="submit"
                className="w-[200px]"
                children={<Text>Create Client</Text>}
              ></Button>
            </Column>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ClientTabComponent;
