import { Input, Column, Text, Button, Radio, RadioGroup } from "components/";
import { Row } from "components/Row";
import { Formik, Form } from "formik";
import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { useCreateUserMutation } from "features/users/usersApiSlice";
import { useGetInstitutionsQuery } from "features/institution/institutionApiSlice";
import Toast from "components/Toast";
import * as Yup from "yup";
const CustomPaper = (props) => {
  return <Paper elevation={8} {...props} />;
};
const UserCreateModal = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    bgcolor: "background.paper",
    border: "2px solid #ffffff",
    borderRadius: 5,
    boxShadow: 24,
    px: 7,
    py: 5,
  };
  const [errMsg, setErrMsg] = useState("");
  const [successMessage, setSuccessMsg] = useState("");
  const [createUser, { isLoading }] = useCreateUserMutation();
  const { data, isLoadingg, isSuccess, isError, error } =
    useGetInstitutionsQuery();

  let list = data?.map((item, index) => {
    return <option value={item.id}>{item.name}</option>;
  });
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Required"),
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string()
      .email("Enter a Valid Email Address")
      .required("Required"),
    phone: Yup.string()
      .required("Required")
      .min(10, "Must be a valid phone number"),
    role: Yup.string().required("Required"),
    institution: Yup.number().required("Required"),
    position: Yup.string().required("Required"),
  });
  const initialValues = {
    username: "",
    firstName: "",
    lastName: "",
    phone: "",
    role: "",
    email: "",
    institution: -1,
    position: "",
  };
  return (
    <Box className="w-[60%] md:w-[600px] h-[75vh]" sx={style}>
      <div style={{ overflow: "scroll", height: "65vh" }}>
        <Text className="not-italic text-gray_900 w-[auto]" variant="h2">
          New User Account
        </Text>
        {errMsg && <Toast message={errMsg} />}
        {successMessage && <Toast type="success" message={successMessage} />}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            try {
              const userData = await createUser({
                username: values.username,
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                phone: values.phone,
                role: values.role,
                institution: values.institution,
                position: values.position,
              }).unwrap();
              setErrMsg("");
              setSuccessMsg(
                "Successfully created user " +
                  values.firstName +
                  " " +
                  values.lastName
              );
              resetForm();
            } catch (err) {
              if (!err) {
                setErrMsg("No Server Response");
              } else if (err.data?.username) setErrMsg(err.data?.username[0]);
              else if (err.data?.email) setErrMsg(err.data?.email[0]);
              else if (err.data?.phone) setErrMsg(err.data?.phone[0]);
              else if (err.status === 400) setErrMsg("Invalid Credentials");
              else if (err.status === 401) {
                setErrMsg("Unauthorized");
              } else {
                setErrMsg("Login Failed");
              }
            }
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Column className="gap-2 mt-3">
                {isLoading && (
                  <Box
                    sx={{
                      position: "relative",
                      top: 0,
                      width: "100%",
                      height: "50px",
                    }}
                  >
                    <LinearProgress className="bg-blue_labex" />
                  </Box>
                )}
                <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                  <Text className="not-italic text-gray_900" variant="h6">
                    Username
                  </Text>
                  <Input
                    className="diasable w-[100%]"
                    wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                    name="username"
                    {...formik.getFieldProps("username")}
                  ></Input>
                </Row>
                {formik.touched?.username && formik.errors?.username ? (
                  <div>
                    <Text
                      className="not-italic text-red_700_cc w-[auto]"
                      variant="body2"
                    >
                      {formik.errors.username}
                    </Text>
                  </div>
                ) : null}
                <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px]"
                    variant="h6"
                  >
                    First Name
                  </Text>
                  <Input
                    className="w-[100%]"
                    wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                    name="firstName"
                    {...formik.getFieldProps("firstName")}
                  ></Input>
                </Row>
                {formik.touched?.firstName && formik.errors?.firstName ? (
                  <div>
                    <Text
                      className="not-italic text-red_700_cc w-[auto]"
                      variant="body2"
                    >
                      {formik.errors.firstName}
                    </Text>
                  </div>
                ) : null}
                <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px]"
                    variant="h6"
                  >
                    Last Name
                  </Text>
                  <Input
                    className="w-[100%]"
                    wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                    name="lastName"
                    {...formik.getFieldProps("lastName")}
                  ></Input>
                </Row>
                {formik.touched?.lastName && formik.errors?.lastName ? (
                  <div>
                    <Text
                      className="not-italic text-red_700_cc w-[auto]"
                      variant="body2"
                    >
                      {formik.errors.lastName}
                    </Text>
                  </div>
                ) : null}
                <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px]"
                    variant="h6"
                  >
                    Email Address
                  </Text>
                  <Input
                    className="w-[100%]"
                    wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                    name="email"
                    {...formik.getFieldProps("email")}
                  ></Input>
                </Row>
                {formik.touched?.email && formik.errors?.email ? (
                  <div>
                    <Text
                      className="not-italic text-red_700_cc w-[auto]"
                      variant="body2"
                    >
                      {formik.errors.email}
                    </Text>
                  </div>
                ) : null}
                <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px]"
                    variant="h6"
                  >
                    Phone Number
                  </Text>
                  <Input
                    className="w-[100%]"
                    wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                    name="phone"
                    {...formik.getFieldProps("phone")}
                  ></Input>
                </Row>
                {formik.touched?.phone && formik.errors?.phone ? (
                  <div>
                    <Text
                      className="not-italic text-red_700_cc w-[auto]"
                      variant="body2"
                    >
                      {formik.errors.phone}
                    </Text>
                  </div>
                ) : null}
                <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px]"
                    variant="h6"
                  >
                    Category
                  </Text>
                  <select
                    // color="secondary"
                    className="px-3 bg-gray_50 2xl:mt-[9px]  3xl:mt-[10px] flex 3xl:h-[56px] 2xl:h-[47px] xl:h-[41px] lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[160px]  sm:w-[300px]  xl:mt-[8px] border border-gray_50 border-solid rounded-radius845"
                    id="role"
                    label="Role"
                    name="role"
                    {...formik.getFieldProps("role")}
                    // value={data?.data.brand}
                    // {...formik.getFieldProps("brand")}
                  >
                    <option></option>
                    <option value="soyo">Labex Officer </option>

                    <option value="admin">Administrator</option>
                    <option value="client">Laboratory/Organization</option>
                    <option value="supplier">Supplier</option>
                  </select>
                </Row>
                {formik.touched?.role && formik.errors?.role ? (
                  <div>
                    <Text
                      className="not-italic text-red_700_cc w-[auto]"
                      variant="body2"
                    >
                      {formik.errors.role}
                    </Text>
                  </div>
                ) : null}
                <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px]"
                    variant="h6"
                  >
                    Position
                  </Text>
                  <Input
                    className="w-[100%]"
                    wrapClassName=" 2xl:mt-[9px] 3xl:mt-[10px] flex 3xl:h-[56px] 2xl:h-[47px] xl:h-[41px] lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:mt-[8px]"
                    name="position"
                    {...formik.getFieldProps("position")}
                  ></Input>
                </Row>
                {formik.touched?.position && formik.errors?.position ? (
                  <div>
                    <Text
                      className="not-italic text-red_700_cc w-[auto]"
                      variant="body2"
                    >
                      {formik.errors.position}
                    </Text>
                  </div>
                ) : null}

                {/* <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text
                  className="not-italic text-gray_900 w-[150px]"
                  variant="h6"
                >
                  Institution
                </Text>

                <select
                  // color="secondary"
                  className="px-3 bg-gray_50 2xl:mt-[9px]  3xl:mt-[10px] flex 3xl:h-[56px] 2xl:h-[47px] xl:h-[41px] lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[160px]  sm:w-[300px]  xl:mt-[8px] border border-gray_50 border-solid rounded-radius845"
                  id="institution"
                  label="Institution"
                  name="institution"
                 
                  // value={data?.data.brand}
                  {...formik.getFieldProps("institution")}
                >
                  <option></option>
                  {list}
                </select>

               
              </Row> */}

                <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                  <Text
                    className="not-italic text-gray_900 w-[150px]"
                    variant="h6"
                  >
                    Institutions
                  </Text>
                  <Autocomplete
                    PaperComponent={CustomPaper}
                    sx={
                      {
                        // backgroundColor: "green",
                      }
                    }
                    onChange={(e, value) => {
                      formik.setFieldValue(
                        "institution",
                        value !== null ? value.id : initialValues.institution
                      );
                    }}
                    id="institution"
                    name="institution"
                    options={data ? data : []}
                    // {...formik.getFieldProps('i')}

                    className="w-[160px] 3xl:h-[56px] 2xl:h-[47px] xl:h-[41px] lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] bg-gray_50 border border-gray_50 border-solid rounded-radius845 sm:w-[300px]"
                    getOptionLabel={(option) => option.name}
                    // defaultValue={data??data[1]}
                    // inputValue={null}
                    renderInput={(params) => (
                      <TextField
                        className="h-[100px] px-3 "
                        {...params}
                        variant="standard"
                        // placeholder="Items to add"
                        name="institution"
                        // color="#456686"
                      />
                    )}
                  />
                </Row>
                {formik.touched?.institution && formik.errors?.institution ? (
                  <div>
                    <Text
                      className="not-italic text-red_700_cc w-[auto]"
                      variant="body2"
                    >
                      {formik.errors.institution}
                    </Text>
                  </div>
                ) : null}
                <Button
                  // onClick={() => handleSubmit()}
                  type="submit"
                  className="w-[200px] mt-2"
                  children="Create User"
                ></Button>
              </Column>
            </form>
          )}
        </Formik>
      </div>
    </Box>
  );
};

export default UserCreateModal;
